import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Contador from './pages/Contador/Contador';
import CadastroContador from './pages/Contador/CadastroContador';
import Revenda from './pages/Revenda/Revenda';
import CadastroRevenda from './pages/Revenda/CadastroRevenda';
import CadastroUsuario from './pages/Usuarios/CadastroUsuario';
import Empresas from './pages/Empresas/Empresas';
import CadastroEmpresa from './pages/Empresas/CadastroEmpresa';
import CadastroEmpresaSite from './pages/Empresas/CadastroEmpresaSite';
import Financeiro from './pages/Financeiro/Financeiro';
import Caixa from './pages/Caixa/Caixa';
import Xml from './pages/Xml/Xml';
import DetalhesXml from './pages/Xml/DetalhesXml';
import ContraChave from './pages/ContraChave/ContraChave';
import Downloads from './pages/Downloads/Downloads';

import { permissoes } from './data/options';

const isAuthentication = () => {
    const infoLogin = sessionStorage.getItem('infoLogin') || null;
    const jwt = sessionStorage.getItem('jwtRenove') || null;
    if(infoLogin && jwt){
        return true;
    }else{
        return false;
    }
};

const isAuthorized = (path) => {
    const infoLogin = JSON.parse(sessionStorage.getItem('infoLogin')) || null;
    if(infoLogin){
        const explod = infoLogin.permissao.split(',');
        const rota = permissoes.filter(item => item.rota.includes(path));
        if(rota.length > 0){
            const permite = explod.filter(item => item === rota[0].value);
            if(permite.length > 0) return true;
        }
    }
    return false;
}

const PrivateRoute = (props) => {
    const { path, location, component: Component } = props;
    return(
        <Route path={path} >
            {(!isAuthentication()) ? 
                <Login /> : 
            (isAuthorized(path)) ? 
                <Component location={location} /> : 
                <Home /> 
            }
        </Route>
    );
};

export default function Routes() {
    return(
        <BrowserRouter>
            <Switch>
                <PrivateRoute path="/contadores" component={ Contador } />
                <PrivateRoute path="/cadastrocontador" component={ CadastroContador } />
                <PrivateRoute path="/revenda" component={ Revenda } />
                <PrivateRoute path="/cadastrorevenda" component={ CadastroRevenda } />
                <PrivateRoute path="/cadastrousuario" component={ CadastroUsuario } />
                <PrivateRoute path="/empresas" component={ Empresas } />
                <PrivateRoute path="/cadastroempresa" component={ CadastroEmpresa } />
                <PrivateRoute path="/cadastroempresasite" component={ CadastroEmpresaSite } />
                <PrivateRoute path="/financeiro" component={ Financeiro } />
                <PrivateRoute path="/caixa" component={ Caixa } />
                <PrivateRoute path="/xml" component={ Xml } />
                <PrivateRoute path="/detalhesxml" component={ DetalhesXml } />
                <PrivateRoute path="/contrachave" component={ ContraChave } />
                <PrivateRoute path="/downloads" component={ Downloads } />

                <PrivateRoute path="/" component={ Home } />
            </Switch>
        </BrowserRouter>
    );
}