import React, { useState, Fragment, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import qs from 'querystring';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SelectValueForLabel from '../../components/Selects/SelectValueForLabel';
import Input from '../../components/Inputs/Input';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alert/Alert';
import api, { apisafe2pay, apiEmissor, ar } from '../../services/api';
import { endMes, mesAno, startMes, dateBrl } from '../../services/date';
import { displayMoney, converterMoeda } from '../../services/displayValue';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        marginRight: 5,
        marginLeft: -5,
    }
});

export default function DrawerRelCaixa({ state, setState }) {
    const classes = useStyles();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [mes, setMes] = useState(mesAno());
    const [order, setOrder] = useState("5");
    const [origem, setOrigem] = useState("TODOS");
    const [tipo, setTipo] = useState("TODOS");
    const [arrayDeposit, setArrayDeposit] = useState([]);
    const [indexArr, setIndexArr] = useState(0);
    const [arrayDetails, setArrayDetails] = useState([]);
    const [arrayPayment, setArrayPayment] = useState([]);
    const [openLoad, setOpenLoad] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function createPdf(arrData) {
        // let totalTax = 0;
        const arrF = arrData.map(i => {
            const payment = arrayPayment.filter(p => Number(p.IdTransaction) === Number(i.id_transaction));
            const repase = payment.length === 1 ? dateBrl(payment[0].dataRef) : dateBrl(i.data_pagamento);
            const tax = payment.length === 1 ? Number(payment[0].Tax) : 0;
            // totalTax += tax;
            const type = payment.length === 1 ? String(payment[0].PaymentMethod.Name).split(' ')[0] : 'Dinheiro';
            return([
                i.origem, 
                {text: displayMoney(i.liquido ? i.liquido : Number(i.valor) - tax), alignment: 'right'},
                type,
                dateBrl(i.vencimento), 
                dateBrl(i.data_pagamento),
                repase,  
                i.fantasia
            ]);
        });
        let arrSort = arrF.sort((a, b) => {
            return a[order] < b[order] ? -1 : a[order] > b[order] ? 1 : 0;
        });
        if(origem !== "TODOS") arrSort = arrSort.filter(arr => arr[0] === origem);
        if(tipo !== "TODOS") arrSort = arrSort.filter(arr => arr[2] === tipo);
        const arrTable = [['ORIGEM', 'VALOR', 'TIPO', 'VENCIMENTO', 'PAGAMENTO', 'REPASSE', 'EMPRESA'], ...arrSort];

        const erp = arrSort.filter(arr => arr[0] === 'ERP');
        const erpVal = erp.length > 0 ? erp.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num) : 0;
        const erpBoleto = erp.filter(arr => arr[2] === 'Boleto');
        const erpBoletoVal = erpBoleto.length > 0 ? erpBoleto.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num) : 0;
        const erpPix = erp.filter(arr => arr[2] === 'Pix');
        const erpPixVal = erpPix.length > 0 ? erpPix.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num) : 0;
        const erpDinheiro = erp.filter(arr => arr[2] === 'Dinheiro');
        const erpDinheiroVal = erpDinheiro.length > 0 ? erpDinheiro.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num) : 0;

        const site = arrSort.filter(arr => arr[0] === 'SITE');
        const siteVal = site.length > 0 ? site.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num) : 0;
        const ar = arrSort.filter(arr => arr[0] === 'AR');
        const arVal = ar.length > 0 ? ar.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num) : 0;
        const totalGeral = arrSort.map(arr => converterMoeda(arr[1].text)).reduce((sum, num) => sum + num);
        const arrFooter = [
            ['DESCRIÇÃO', {text: 'QUANT', alignment: 'right'}, {text: 'TOTAL', alignment: 'right'}],
            ['BOLETO ERP', {text: erpBoleto.length, alignment: 'right'}, {text: displayMoney(erpBoletoVal), alignment: 'right'}],
            ['PIX ERP', {text: erpPix.length, alignment: 'right'}, {text: displayMoney(erpPixVal), alignment: 'right'}],
            ['DINHEIRO ERP', {text: erpDinheiro.length, alignment: 'right'}, {text: displayMoney(erpDinheiroVal), alignment: 'right'}],
            ['\n', '\n', '\n'],
            ['ERP', {text: erp.length, alignment: 'right'}, {text: displayMoney(erpVal), alignment: 'right'}],
            ['SITE', {text: site.length, alignment: 'right'}, {text: displayMoney(siteVal), alignment: 'right'}],
            ['AR', {text: ar.length, alignment: 'right'}, {text: displayMoney(arVal), alignment: 'right'}],
            [
                'TOTAL SAFE2PAY', 
                {text: Number(arrSort.length) - Number(erpDinheiro.length), alignment: 'right'}, 
                {text: displayMoney(Number(totalGeral) - Number(erpDinheiroVal)), alignment: 'right'}
            ],
            ['TOTAL GERAL', {text: arrSort.length, alignment: 'right'}, {text: displayMoney(totalGeral), alignment: 'right'}],
        ];

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const details = [
            {text: 'RELATORIO DE CAIXA', style: 'header', alignment: 'center'},
            {text: `Periodo: ${dateBrl(startMes(mes))} à ${dateBrl(endMes(mes))}`, alignment: 'center'},
            {
                style: 'table',
                table: {
                    widths: ['*', '*', '*', '*', '*', '*', 'auto'],
                    body: arrTable
                }
            },
            {text: 'RESUMO DE CAIXA', style: 'header'},
            {
                style: 'table',
                table: {
                    widths: ['auto', 'auto', 'auto'],
                    body: arrFooter
                }
            },
        ];
        const docDefine = {
            pageSize: 'A4',
            pageMargins: [15, 50, 15, 40],
            content: [details],
            pageOrientation: 'landscape',
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                }
            }
        };
        if(window && window.ReactNativeWebView){
            pdfMake.createPdf(docDefine).getDataUrl(res => {
                const dataPost = JSON.stringify({pdf: res})
                window.ReactNativeWebView.postMessage(dataPost);
            });
        }else{
            pdfMake.createPdf(docDefine).open();
        }
        setOpenLoad(false);
        setArrayDeposit([]);
        setArrayDetails([]);
        setArrayPayment([]);
        setIndexArr(0);
    }

    async function getDetails() {
        const arrIdTransaction = JSON.stringify(arrayDetails);
        const data = qs.stringify({arrIdTransaction});
        let arrData = [];

        const dataApi = await api.post(`/financeiro/getListTransactions`, data);
        if(dataApi && dataApi.status === 200 && dataApi.data){
            const rowApi = dataApi.data.map(i => ({
                ...i, origem: 'ERP',
                valor: i.valor_boleto,
            }));
            arrData = [...arrData, ...rowApi];
        }

        const dataEmissor = await apiEmissor.post(`/pagamentos/getListTransactions`, data);
        if(dataEmissor && dataEmissor.status === 200 && dataEmissor.data){
            const rowApi = dataEmissor.data.map(i => ({
                ...i, origem: 'SITE',
                valor: i.valor_sistema,
            }));
            arrData = [...arrData, ...rowApi];
        }

        const dataAr = await ar.post(`/safe2pay/getListTransactions`, data);
        if(dataAr && dataAr.status === 200 && dataAr.data){
            const rowApi = dataAr.data.map(i => ({
                ...i, id_transaction: i.id_safe_2_pay, origem: 'AR',
                valor: i.valor_produto, vencimento: i.data_pagamento,
                fantasia: i.titular, razao: i.nome,
            }));
            arrData = [...arrData, ...rowApi];
        }

        const start = startMes(mes);
        const end = endMes(mes)
        const dataRecebidos = await api.get(`/financeiro/loadContasPagas/${infoLogin.id}/${jwt}/${start}/${end}`);
        if(dataRecebidos && dataRecebidos.status === 200){
            const arrDataRecebidos = dataRecebidos.data;
            let filArrRecebidos = arrDataRecebidos.filter(i => Number(i.recebido) === 1);
            console.log(filArrRecebidos);
            if(filArrRecebidos.length > 0){
                const formatRecebidos = filArrRecebidos.map(i => ({...i, origem: 'ERP', valor: i.valor_boleto}));
                console.log(formatRecebidos);
                arrData = [...arrData, ...formatRecebidos];
            }
        }

        createPdf(arrData);
    }

    async function loadData() {
        if(Number(infoLogin.revenda) === 1 && String(infoLogin.api_safe2pay).length === 0) return;
        if(arrayDeposit.length > 0){
            if(arrayDeposit[indexArr]){
                setOpenLoad(true);
                const dataRef = arrayDeposit[indexArr].DepositDate;
                const arrRanger = dataRef.split('-');
                const parms = `day=${arrRanger[2]}&month=${arrRanger[1]}&year=${arrRanger[0]}&page=1&rowsPerPage=1000`;
                const url = `/CheckingAccount/GetListDetailsDeposits?${parms}`;
                const response = await apisafe2pay.get(url, {
                    headers: {
                        safe2pay: infoLogin.api_safe2pay
                    }
                });
                const extrac = response.data.ResponseDetail.Extracts;
                const addExt = extrac.map(i => ({...i, dataRef}));
                setArrayPayment([...arrayPayment, ...addExt]);
                const arrIdTransaction = extrac.map(item => item.IdTransaction);
                setArrayDetails([...arrayDetails, ...arrIdTransaction]);
                setIndexArr(indexArr + 1);
            }else{
                getDetails();
            }
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), [indexArr, arrayDeposit]);

    async function viewRel() {
        if(Number(infoLogin.revenda) === 1 && String(infoLogin.api_safe2pay).length === 0) return;
        const arrRanger = mes.split('-');
        if(arrRanger.length === 2){
            setOpenLoad(true);
            let title = "FALHA NA OPERAÇÃO";
            try {
                const url = `/CheckingAccount/GetListDeposits?month=${Number(arrRanger[1])}&year=${arrRanger[0]}`;
                const response = await apisafe2pay.get(url, {
                    headers: {
                        safe2pay: infoLogin.api_safe2pay
                    }
                });
                if(response && response.status === 200 && response.data){
                    if(response.data.HasError){
                        title = "NÃO FOI POSSIVEL CONSULTAR";
                        console.log(response);
                    }else{
                        const data = response.data.ResponseDetail.Deposits;
                        const safe2pay = data.filter(item => item.Amount > 0);
                        if(safe2pay.length > 0){
                            setArrayDeposit(safe2pay);
                            return;
                        }
                        title = "NENHUM MOVIMENTO ENCONTRADO";
                    }
                }
            } catch (error) {
                console.log(error);
            }
            setOpenLoad(false);
            setState(false);
            AlertInfo('error', title, "");
        }
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="RELATORIO" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                <ListItem>
                    <Input
                        label="MES"
                        type="month"
                        value={mes}
                        onChange={e => setMes(e.target.value)}
                    />
                </ListItem>

                <ListItem>
                    <SelectValueForLabel
                        label="ORDEM"
                        value={order}
                        setValue={setOrder}
                        variant="outlined"
                        options={[
                            {value: '0', label: 'ORIGEM'},
                            {value: '1', label: 'VALOR'},
                            {value: '2', label: 'TIPO'},
                            {value: '3', label: 'VENCIMENTO'},
                            {value: '4', label: 'PAGAMENTO'},
                            {value: '5', label: 'REPASSE'},
                            {value: '6', label: 'EMPRESA'},
                        ]}
                    />
                </ListItem>

                <ListItem>
                    <SelectValueForLabel
                        label="ORIGEM"
                        value={origem}
                        setValue={setOrigem}
                        variant="outlined"
                        options={[
                            {value: 'TODOS', label: 'TODOS'},
                            {value: 'ERP', label: 'ERP'},
                            {value: 'SITE', label: 'SITE'},
                            {value: 'AR', label: 'AR'},
                        ]}
                    />
                </ListItem>
                <ListItem>
                    <SelectValueForLabel
                        label="TIPO"
                        value={tipo}
                        setValue={setTipo}
                        variant="outlined"
                        options={[
                            {value: 'TODOS', label: 'TODOS'},
                            {value: 'Dinheiro', label: 'DINHEIRO'},
                            {value: 'Boleto', label: 'BOLETO'},
                            {value: 'Pix', label: 'PIX'},
                        ]}
                    />
                </ListItem>

                <ListItem>
                    <Button 
                        onClick={() => viewRel()}
                        fullWidth 
                        variant="contained" 
                        color="secondary"
                    >
                        RELATORIO
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            <SimpleBackdrop
                open={openLoad}
                msg={`BUSCANDO REGISTROS ${indexArr}/${arrayDeposit.length}`}
                zIndex={9999}
            />
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
