import styled from 'styled-components';

export const ContainerPage = styled.div`
    width: 100%;
    height: 100%;
    background-color: #d2d6de;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerIcon = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
`;

export const AvatarIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #a71a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
`;

export const stylePaper = { 
    width: '100%',
    maxWidth: '400px',
    margin: '10px',
    padding: '40px 20px',
};