import React, { useState, useEffect } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import qs from 'querystring';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Template from '../../components/Template/Template';
import Input from '../../components/Inputs/Input';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import TableData from '../../components/Table/TableData';
import { CardSmall } from '../../components/Card/Card';
import { mesAno, dateBrl, startMes, endMes } from '../../services/date';
import { displayMoney } from '../../services/displayValue';
import api, { apisafe2pay, apiEmissor, ar } from '../../services/api';
import './caixa.css';

import DrawerRelCaixa from './DrawerRelCaixa';

export default function Caixa() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [api_safe2pay] = useState(infoLogin.api_safe2pay);
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [openLoad, setOpenLoad] = useState(false);
    const [ranger, setRanger] = useState(mesAno());
    const [rowsApi, setRowsApi] = useState([]);
    const [days, setDays] = useState([]);
    const [selectDay, setSelectDay] = useState({});
    const [valueSafe2Pay, setValueSafe2Pay] = useState(0);
    const [valueRecebido, setValueRecebido] = useState(0);
    const [valueAr, setValueAr] = useState(0);
    const [valueSite, setValueSite] = useState(0);
    const [valueErp, setValueErp] = useState(0);
    const [quantSafe2Pay, setQuantSafe2Pay] = useState(0);
    const [quantRecebido, setQuantRecebido] = useState(0);
    const [quantAr, setQuantAr] = useState(0);
    const [quantSite, setQuantSite] = useState(0);
    const [quantErp, setQuantErp] = useState(0);
    const [openDrawerRel, setOpenDrawerRel] = useState(false);
    const [columns] = useState([
        { id: 'id_transaction', label: 'ID SAFE', minWidth: 20 },
        { id: 'origem', label: 'ORIGEM', minWidth: 20 },
        { id: 'valor', label: 'VALOR', minWidth: 50, format: displayMoney, align: 'right' },
        { id: 'vencimento', label: 'VENCIMENTO', minWidth: 50, format: dateBrl },
        { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
        { id: 'razao', label: 'RAZAO', minWidth: 200 },
    ]);

    const isColor = (row) => {
        if(row && row.recebido){
            if(Number(row.recebido) === 1) return "#248fb2";
        }
        return '#000';
    }

    const calcDaysCalendar = () => {
        const inicialDayWeek = moment(ranger).startOf('month').day();
        const finalDayWeek = moment(ranger).endOf('month').day();
        const finalDay = moment(ranger).endOf('month').format('D');
        const possibleDays = [
            "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", 
            "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", 
            "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"
        ];
        let daysOfMonth = possibleDays.filter(d => Number(d) <= Number(finalDay));
        for(let index = 0; index < inicialDayWeek; index++){
            daysOfMonth.unshift("");
        }
        for(let index = finalDayWeek; index < 7; index++){
            daysOfMonth.push("");
        }
        const calendar = daysOfMonth.map(d => ({day: d, values: []}));
        return calendar;
    }

    async function loadData() {
        if(Number(infoLogin.revenda) === 1 && String(infoLogin.api_safe2pay).length === 0) return;
        const arrRanger = ranger.split('-');
        if(arrRanger.length === 2){
            setOpenLoad(true);
            const month = calcDaysCalendar();
            let safe2pay = [];
            let dataApi = [];
            const url = `/CheckingAccount/GetListDeposits?month=${Number(arrRanger[1])}&year=${arrRanger[0]}`;
            const response = await apisafe2pay.get(url, {
                headers: {
                    safe2pay: api_safe2pay
                }
            });
            if(response && response.status === 200){
                const data = response.data.ResponseDetail.Deposits;
                safe2pay = data.filter(item => item.Amount > 0);
            }
            const url2 = `/financeiro/getListDeposits/${startMes(ranger)}/${endMes(ranger)}/${infoLogin.id}`;
            const resp = await api.get(url2);
            console.log(resp);
            if(resp.status === 200 && resp.data) dataApi = resp.data;

            const calendar = month.map(day => {
                const dataRef = `${ranger}-${day.day}`;
                const movsafe2pay = safe2pay.filter(i => i.DepositDate === dataRef);
                const movdataApi = dataApi.filter(i => i.data_pagamento === dataRef);
                const values = [...movsafe2pay, ...movdataApi];
                return {...day, values};
            });

            setDays(calendar);
            setOpenLoad(false);
        }
    }

    async function viewDetails(details) {
        if(details.values.length > 0){
            console.log(details);
            setOpenLoad(true);
            setSelectDay(details);
            const arrRanger = ranger.split('-');
            const parms = `day=${details.day}&month=${arrRanger[1]}&year=${arrRanger[0]}&page=1&rowsPerPage=1000`;
            const url = `/CheckingAccount/GetListDetailsDeposits?${parms}`;
            const response = await apisafe2pay.get(url, {
                headers: {
                    safe2pay: api_safe2pay
                }
            });
            console.log(response);
            if(response && response.status === 200 && response.data){
                if(!response.data.HasError){
                    const extract = response.data.ResponseDetail.Extracts;
                    setQuantSafe2Pay(extract.length);
                    const arrIdTransaction = JSON.stringify(extract.map(i => i.IdTransaction));
                    console.log(arrIdTransaction);
                    const data = qs.stringify({arrIdTransaction});
                    let arrData = [];
                    
                    const dataApi = await api.post(`/financeiro/getListTransactions`, data);
                    if(dataApi && dataApi.status === 200 && dataApi.data){
                        const rowApi = dataApi.data.map(i => ({
                            ...i, origem: 'ERP',
                            valor: i.valor_boleto,
                        }));
                        setQuantErp(dataApi.data.length);
                        if(rowApi.length > 0) setValueErp(rowApi.map(i => Number(i.valor)).reduce((sum, i) => sum + i));
                        arrData = [...arrData, ...rowApi];
                    }

                    const dataEmissor = await apiEmissor.post(`/pagamentos/getListTransactions`, data);
                    if(dataEmissor && dataEmissor.status === 200 && dataEmissor.data){
                        const rowApi = dataEmissor.data.map(i => ({
                            ...i, origem: 'SITE',
                            valor: i.valor_sistema,
                        }));
                        setQuantSite(dataEmissor.data.length);
                        if(rowApi.length > 0) setValueSite(rowApi.map(i => Number(i.valor)).reduce((sum, i) => sum + i));
                        arrData = [...arrData, ...rowApi];
                    }

                    const dataAr = await ar.post(`/safe2pay/getListTransactions`, data);
                    if(dataAr && dataAr.status === 200 && dataAr.data){
                        const rowApi = dataAr.data.map(i => ({
                            ...i, id_transaction: i.id_safe_2_pay, origem: 'AR',
                            valor: i.valor_produto, vencimento: i.data_pagamento,
                            fantasia: i.titular, razao: i.nome,
                        }));
                        setQuantAr(dataAr.data.length);
                        if(rowApi.length > 0) setValueAr(rowApi.map(i => Number(i.valor)).reduce((sum, i) => sum + i));
                        arrData = [...arrData, ...rowApi];
                    }

                    const isRecebidos = details.values[1] ? details.values[1].data_pagamento : null;
                    if(isRecebidos){
                        const dataRecebidos = await api.get(`/financeiro/loadContasPagas/${infoLogin.id}/${jwt}/${isRecebidos}/${isRecebidos}`);
                        if(dataRecebidos && dataRecebidos.status === 200){
                            const arrDataRecebidos = dataRecebidos.data;
                            const filArrRecebidos = arrDataRecebidos.filter(i => Number(i.recebido) === 1);
                            if(filArrRecebidos.length > 0){
                                const formatRecebidos = filArrRecebidos.map(i => ({...i, origem: 'ERP', valor: i.valor_boleto}));
                                arrData = [...arrData, ...formatRecebidos];
                                setQuantRecebido(formatRecebidos.length);
                            }
                        }
                    }else{
                        setQuantRecebido(0);
                    }

                    setValueSafe2Pay(details.values[0] ? details.values[0].Amount : 0);
                    setValueRecebido(details.values[1] ? details.values[1].Amount : 0);
                    setRowsApi(arrData);
                    window.scrollTo(1000, 1000);
                }
            }
            setOpenLoad(false);
        }else{
            setRowsApi([]);
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), [ranger]);

    return(
        <Template menuActive="caixa">
            <SimpleBackdrop open={openLoad} />
            <DrawerRelCaixa
                state={openDrawerRel}
                setState={setOpenDrawerRel}
            />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Input 
                        type="month"
                        size="small"
                        label="MÊS"  
                        value={ranger}
                        fullWidth
                        onChange={(e) => setRanger(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setOpenDrawerRel(true)}
                    >
                        RELATORIOS
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <div className='mediaCalendar'>
                        <div className="containerCalendar">
                            <div className='rowCalendar'>
                                <div className='calendarBox'>DOM</div>
                                <div className='calendarBox'>SEG</div>
                                <div className='calendarBox'>TER</div>
                                <div className='calendarBox'>QUA</div>
                                <div className='calendarBox'>QUI</div>
                                <div className='calendarBox'>SEX</div>
                                <div className='calendarBox'>SAB</div>
                            </div>
                            <div className='rowCalendar'>
                                {days.slice(0, 7).map((d) => (
                                    <div className='calendarBox boxCell' onClick={() => viewDetails(d)}>
                                        <div>{d.day}</div>
                                        {d.values.map(item => (
                                            <div className={item.quant?`isHand`:item.IsTransferred?`isPag`:'isLoad'}>
                                                {displayMoney(item.Amount)}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className='rowCalendar'>
                                {days.slice(7, 14).map((d) => (
                                    <div className='calendarBox boxCell' onClick={() => viewDetails(d)}>
                                        <div>{d.day}</div>
                                        {d.values.map(item => (
                                            <div className={item.quant?`isHand`:item.IsTransferred?`isPag`:'isLoad'}>
                                                {displayMoney(item.Amount)}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className='rowCalendar'>
                                {days.slice(14, 21).map((d) => (
                                    <div className='calendarBox boxCell' onClick={() => viewDetails(d)}>
                                        <div>{d.day}</div>
                                        {d.values.map(item => (
                                            <div className={item.quant?`isHand`:item.IsTransferred?`isPag`:'isLoad'}>
                                                {displayMoney(item.Amount)}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className='rowCalendar'>
                                {days.slice(21, 28).map((d) => (
                                    <div className='calendarBox boxCell' onClick={() => viewDetails(d)}>
                                        <div>{d.day}</div>
                                        {d.values.map(item => (
                                            <div className={item.quant?`isHand`:item.IsTransferred?`isPag`:'isLoad'}>
                                                {displayMoney(item.Amount)}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className='rowCalendar'>
                                {days.slice(28, 35).map((d) => (
                                    <div className='calendarBox boxCell' onClick={() => viewDetails(d)}>
                                        <div>{d.day}</div>
                                        {d.values.map(item => (
                                            <div className={item.quant?`isHand`:item.IsTransferred?`isPag`:'isLoad'}>
                                                {displayMoney(item.Amount)}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className='rowCalendar'>
                                {days.slice(35, 42).map((d) => (
                                    <div className='calendarBox boxCell' onClick={() => viewDetails(d)}>
                                        <div>{d.day}</div>
                                        {d.values.map(item => (
                                            <div className={item.quant?`isHand`:item.IsTransferred?`isPag`:'isLoad'}>
                                                {displayMoney(item.Amount)}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Grid>

                {rowsApi.length > 0 && (
                    <>
                        <Grid item xs={12} sm={3}>
                            <CardSmall color="#091114">
                                <h1><FaCalendarAlt /></h1>
                                <div>
                                    <span>DATA</span>
                                    <br />
                                    <span>{dateBrl(`${ranger}-${selectDay.day}`)}</span>
                                </div>
                            </CardSmall>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <CardSmall color={selectDay.values[0].IsTransferred ? "#6bbd5b" : "#091114"}>
                                <h1>{quantSafe2Pay}</h1>
                                <div>
                                    <span>SAFE2PAY</span>
                                    <br />
                                    <span>
                                        {displayMoney(valueSafe2Pay)}
                                    </span>
                                </div>
                            </CardSmall>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <CardSmall color="#248fb2">
                                <h1>{quantRecebido}</h1>
                                <div>
                                    <span>EM MAOS</span>
                                    <br />
                                    <span>{displayMoney(valueRecebido)}</span>
                                </div>
                            </CardSmall>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <CardSmall color="#091114">
                                <h1>{quantSafe2Pay + quantRecebido}</h1>
                                <div>
                                    <span>TOTAL</span>
                                    <br />
                                    <span>{displayMoney(Number(valueSafe2Pay) + Number(valueRecebido))}</span>
                                </div>
                            </CardSmall>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <CardSmall color="#091114">
                                <h1>{quantErp}</h1>
                                <div>
                                    <span>ERP</span>
                                    <br />
                                    <span>{displayMoney(valueErp)}</span>
                                </div>
                            </CardSmall>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CardSmall color="#091114">
                                <h1>{quantSite}</h1>
                                <div>
                                    <span>SITE</span>
                                    <br />
                                    <span>{displayMoney(valueSite)}</span>
                                </div>
                            </CardSmall>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CardSmall color="#091114">
                                <h1>{quantAr}</h1>
                                <div>
                                    <span>AR</span>
                                    <br />
                                    <span>{displayMoney(valueAr)}</span>
                                </div>
                            </CardSmall>
                        </Grid>

                        <Grid item xs={12} id="tableDetails">
                            <Paper elevation={3}>
                                <TableData
                                    columns={columns}
                                    rows={rowsApi}
                                    clickFunction={() => {}}
                                    vh="60vh"
                                    isColor={isColor}
                                />
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </Template>
    );
}