import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *, input, button {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }

  html, body, #root {
    height: 100%;
    background-color: #fff;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  input {
    text-transform: uppercase;
  }

  .successColor {
    background-color: #1ba300 !important;
    color: #fff !important;
  }

  .infoColor {
    background-color: #162aac !important;
    color: #fff !important;
  }
  
`
