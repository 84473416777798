import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import api from '../../services/api';

export default function Contador() {
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = [
        { id: 'id', label: 'CODIGO', minWidth: 30 },
        { id: 'fantasia', label: 'ESCRITORIO', minWidth: 150 },
        { id: 'proprietario', label: 'CONTADOR', minWidth: 150 },
        { id: 'cidade', label: 'CIDADE', minWidth: 170 },
    ];
    const history = useHistory();

    function logout() {}

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`/contador/listContadores/${infoLogin.id}/${jwt}`);
        console.log(response);
        if(response.status === 200){
            if(response.data.invalidJwtt) logout();
            else setRows(response.data);
        }
        setOpenLoad(false);
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    function handleCadastro(info) {
        const location = {
            pathname: '/cadastrocontador',
            state: { id_contador: info.id },
        }
        history.push(location);
    }

    return(
        <Template menuActive="contadores">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleCadastro({id: 0})}
                    >
                        NOVO CONTADOR
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableData
                        columns={columns}
                        rows={rows}
                        clickFunction={handleCadastro}
                    />
                </Grid>
            </Grid>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}