import { useEffect, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Template from '../../components/Template/Template';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function Downloads() {
    const [activeStep, setActiveStep] = useState(0);
    const [infoErp, setInfoErp] = useState([]);
    const [infoPdv, setInfoPdv] = useState([]);
    const [infoOs, setInfoOs] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [infoModal, setInfoModal] = useState([]);
    const steps = ['ERP RENOVE', 'MODULO PDV', 'MODULO O.S'];
    const classes = useStyles();

    async function handleOldVersions(program) {
        try {
            const response = await api.get(`/version/listAllVersionProgram/${program}`);
            if(response && response.status === 200 && response.data){
                setInfoModal(response.data);
                setOpenModal(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function ModalAllVersions() {
        return (
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">TODAS A NOTAS DE VERSÃO</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <ul>
                            {infoModal.map(data => (
                                <li key={data.id_version}>
                                    <Typography component="p" style={{color: '#000'}}>{`(${data.version}) - ${data.descricao_version}`}</Typography>
                                </li>
                            ))}
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function downloadExe(program) {
        const url = `https://renovetecnologia.com/update?program=${program}`;
        window.open(url, '_blank');
    }

    function ComponentProgram({ info, program, keyProgram }) {
        return (
            <Grid container spacing={3}>
                {info.length > 0 && (
                    <Grid item xs={12}>
                        <Typography component="h6" style={{ fontWeight: 'bold' }}>* NOTAS DA VERSÃO {info[0].version} *</Typography>
                        <ul>
                            {info.map(data => (
                                <li key={data.id_version}>
                                    <Typography component="p">{data.descricao_version}</Typography>
                                </li>
                            ))}
                        </ul>
                        <hr />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div 
                        style={{ display: 'flex', alignItems: 'center', gap: 8, cursor: 'pointer' }}
                        onClick={() => handleOldVersions(keyProgram)}
                    >
                        <FaHistory />
                        <Typography component="h6">Notas Anteriores</Typography>
                    </div>
                </Grid>
                {program.map(item => (
                    <Grid item xs={12} sm={3}>
                        <Button
                            color='primary'
                            fullWidth
                            variant='contained'
                            onClick={() => downloadExe(item.program)}
                        >
                            {item.title}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        );
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <ComponentProgram 
                        info={infoErp} 
                        program={[
                            {title: "INSTALADOR", program: "sistemarenove_install"}, 
                            {title: "ATUALIZADOR", program: "sistemarenove_update"}
                        ]} 
                        keyProgram="erp"
                    />
                );
            case 1:
                return (
                    <ComponentProgram 
                        info={infoPdv} 
                        program={[{title: "BAIXAR", program: "pdv_renove"}]} 
                        keyProgram="pdv"
                    />
                );
            case 2:
                return (
                    <ComponentProgram 
                        info={infoOs} 
                        program={[{title: "BAIXAR", program: "os_renove_install"}]} 
                        keyProgram="os"
                    />
                );
            default:
                return 'Unknown step';
        }
    }

    async function loadData() {
        const programs = ['erp', 'pdv', 'os'];
        const infos = { erp: setInfoErp, pdv: setInfoPdv, os: setInfoOs };
        const program = programs[activeStep];
        try {
            const response = await api.get(`/version/getLastVersion/${program}`);
            if (response && response.status === 200) {
                const setInfo = infos[program];
                setInfo(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), [activeStep]);

    return (
        <Template menuActive="downloads">
            <ModalAllVersions />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h1 style={{ textAlign: 'center' }}>DOWNLOADS</h1>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel style={{ cursor: 'pointer' }} onClick={() => setActiveStep(index)}>{label}</StepLabel>
                                    <StepContent>
                                        <Typography>{getStepContent(index)}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </Grid>
            </Grid>
        </Template>
    );
}