import qs from 'querystring';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Template from '../../components/Template/Template';
import Input from '../../components/Inputs/Input';
import SelectValueForLabel from '../../components/Selects/SelectValueForLabel';
import AutoCompleteCity from '../../components/Selects/AutoCompleteCity';
import Snack from '../../components/Alert/Snack';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertConfirm, AlertInfo } from '../../components/Alert/Alert';
import { arrayUfCode } from '../../data/options';
import { maskFone, removeMask } from '../../services/mask';
import api, { apiEmissor, backendURL, consultaCnpj } from '../../services/api';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        backgroundColor: '#a71a1a',
        fontSize: '22pt',
    },
    box: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
    },
    user: {
        margin: '20px 0',
    }
}));

export default function CadastroContador(props) {
    const { id_contador } = props.location.state;
    const classes = useStyles();
    const history = useHistory();
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));

    const [infoUser, setInfoUser] = useState({});
    const [id, setId] = useState(null);
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [razao, setRazao] = useState('');
    const [fantasia, setFantasia] = useState('');
    const [cpf_cnpj, setCpfCnpj] = useState('');
    const [proprietario, setProprietario] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [uf, setUf] = useState('15');
    const [cidade, setCidade] = useState('');
    const [cep, setCep] = useState('');
    const [telefone1, setTelefone1] = useState('');
    const [telefone2, setTelefone2] = useState('');
    const [email, setEmail] = useState('');
    const [user_id_create, setUserIdCreate] = useState(infoLogin.id);
    const [openLoad, setOpenLoad] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [msgSnack, setMsgSnack] = useState('');

    async function handleCadastro() {
        if(usuario.trim().length === 0){
            setMsgSnack('Preencha campo usuario');
            setOpenSnack(true);
            return;
        }
        if(!id && senha.length === 0){
            setMsgSnack('Preencha campo senha');
            setOpenSnack(true);
            return;
        }
        if(Number(user_id_create) !== Number(infoLogin.id) && Number(infoLogin.id) !== Number(id)){
            setMsgSnack('Voce não possui permissão para esta Ação');
            setOpenSnack(true);
            return;
        }
        setOpenLoad(true);
        const data = {
            ...infoUser,
            razao, fantasia, proprietario,
            endereco, numero, bairro, complemento, cep,
            id, usuario, senha, uf, cpf_cnpj,
            cidade: (cidade && cidade.cidades) ? cidade.cidades : '',
            codigomunicipio: (cidade && cidade.municipio) ? cidade.municipio : '',
            telefone1, telefone2, email, user_id_create
        };
        const urlAction = id ? `updateContador` : `createContador`;
        try {
            const response = await api.post(`/contador/${urlAction}`, qs.stringify(data));
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo('error', response.data.message);
                    console.log(response);
                }else if(response.data.success){
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        history.push('/contadores');
                    });
                }
            }
        } catch (error) {
            console.log(error);
            setMsgSnack('Verifique a conexão com a internet');
            setOpenSnack(true);
        }
        setOpenLoad(false);
    }

    function deleteUser() {
        if(id && Number(user_id_create) === Number(infoLogin.id)){
            AlertConfirm('', 'EXCLUIR CONTADOR DO SISTEMA?', 'A ação não poderá ser desfeita!', 'EXCLUIR').then(async res => {
                if(res.isConfirmed){
                    setOpenLoad(true);
                    const response = await api.post(`/usuarios/deleteUser/${infoLogin.id}/${jwt}`, qs.stringify({id}));
                    if(response && response.status === 200){
                        AlertInfo('success', 'CONTADOR DELETADO').then(() => {
                            history.push('/usuarios');
                        });
                    }
                    setOpenLoad(false);
                }
            });
        }else{
            setMsgSnack('Voce não possui permissão para esta Ação');
            setOpenSnack(true);
        }
    }

    function setDataUser(data = {}) {
        if(data && data.id){
            setId(data.id);
            setUsuario(data.usuario);
            setRazao(data.razao);
            setFantasia(data.fantasia);
            setCpfCnpj(data.cpf_cnpj);
            setProprietario(data.proprietario);
            setEndereco(data.endereco);
            setNumero(data.numero);
            setBairro(data.bairro);
            setComplemento(data.complemento);
            setUf(data.uf);
            setCidade(data.codigomunicipio);
            setCep(data.cep);
            setTelefone1(data.telefone1);
            setTelefone2(data.telefone2);
            setEmail(data.email);
            setUserIdCreate(data.user_id_create);
            setInfoUser(data);
        }
    }

    async function loadData() {
        if(Number(id_contador) > 0){
            setOpenLoad(true);
            try {
                const response = await api.get(`/usuarios/infoUsuario/${infoLogin.id}/${jwt}/${id_contador}`);
                console.log(response);
                if(response && response.status === 200 && response.data){
                    if(response.data.id) setDataUser(response.data);
                }
            } catch (error) {
                console.log(error);
                setMsgSnack('Não foi possivel carregar os dados');
                setOpenSnack(true);
            }
            setOpenLoad(false);
        }else{
            let inputFocus = document.getElementById('cpfcnpj');
            if(inputFocus) inputFocus.focus();
        }
    }

    async function handleCnpj() {
        if(!id){
            const cnpj = removeMask(cpf_cnpj);
            if(String(cnpj) > 0){
                setOpenLoad(true);
                try {
                    const isExist = await api.get(`/usuarios/infoUsuarioByCpfCnpj/${infoLogin.id}/${jwt}/${cnpj}`);
                    if(isExist && isExist.status === 200 && isExist.data){
                        if(isExist.data.id){
                            setDataUser(isExist.data);
                            AlertInfo('error', 'CPF/CNPJ já cadastrado', '');
                            setOpenLoad(false);
                            return;
                        }
                    }
                    if(String(cnpj).length === 14){
                        try {
                            const response = await consultaCnpj.get(`/${cnpj}`);
                            if(response && response.status === 200 && response.data){
                                const dataCompany = response.data;
                                if(dataCompany.status && dataCompany.status === 400){
                                    setMsgSnack(dataCompany.detalhes);
                                    setOpenSnack(true);
                                }else if(dataCompany.cnpj_raiz){
                                    setRazao(dataCompany.razao_social);
                                    setFantasia(dataCompany.estabelecimento.nome_fantasia);
                                    setEndereco(dataCompany.estabelecimento.logradouro);
                                    setNumero(dataCompany.estabelecimento.numero);
                                    setBairro(dataCompany.estabelecimento.bairro);
                                    setComplemento(dataCompany.estabelecimento.complemento ? dataCompany.estabelecimento.complemento : '');
                                    setUf(dataCompany.estabelecimento.estado.ibge_id);
                                    setCidade(dataCompany.estabelecimento.cidade.ibge_id);
                                    setCep(dataCompany.estabelecimento.cep);
                                }
                            }
                        } catch (error) {
                            console.log(error);
                            setMsgSnack('Não foi possivel consultar CNPJ');
                            setOpenSnack(true);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    setMsgSnack('Não foi possivel consultar CNPJ');
                    setOpenSnack(true);
                }
                setOpenLoad(false);
            }
        }
    }

    function relContador() {
        const urlRel = `${backendURL}/contador/relContador/${id}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: urlRel})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(urlRel);
        }
    }

    async function sendUserEmissor() {
        if(id){
            AlertConfirm('', 'ENVIAR DADOS PARA O PAINEL DO EMISSOR?','', 'SIM').then(async res =>{
                setOpenLoad(true);
                try {
                    const response = await apiEmissor.post(`/painel/addUserByAcesso`, qs.stringify(infoUser));
                    if(response && response.status === 200 && response.data){
                        if(response.data.success){
                            AlertInfo('success', 'DADOS GRAVADOS');
                        }else{
                            AlertInfo('error', 'FALHA NA OPERAÇÃO');
                            console.log(response);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo('error', 'FALHA NA CONEXÃO');
                }
                setOpenLoad(false);
            });
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    return(
        <Template menuActive="contadores">
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                message={msgSnack}
                time={3000}
            />
            <SimpleBackdrop
                open={openLoad}
                msg='CARREGANDO...'
            />
            <Grid container spacing={1}>
                <Grid item xs={false} sm={1}></Grid>
                <Grid item xs={false} sm={2}>
                    {id && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => relContador()}
                        >
                            RELATORIO
                        </Button>
                    )}
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={false} sm={2}>
                    {id && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => sendUserEmissor()}
                        >
                            PARA EMISSOR
                        </Button>
                    )}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={false} sm={1} />
                <Grid item xs={12} sm={10}>
                    <Paper elevation={3} className={classes.box}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={9}>
                                <h2>DADOS DA CONTABILIDADE</h2>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                {id && (
                                    <Input
                                        size="small"
                                        label="COD."
                                        value={id}
                                        onChange={() => {}}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                {id && (
                                    <Fab 
                                        color="primary" 
                                        aria-label="deletar" 
                                        size='small'
                                        onClick={() => deleteUser()}
                                    >
                                        <FaTrash />
                                    </Fab>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Input
                                    id="cpfcnpj"
                                    size="small"
                                    label="CPF CNPJ"
                                    value={cpf_cnpj}
                                    onChange={e => setCpfCnpj(e.target.value)}
                                    onBlur={handleCnpj}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Input
                                    id="razao"
                                    size="small"
                                    label="RAZAO SOCIAL"
                                    value={razao}
                                    onChange={e => setRazao(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Input
                                    id="fantasia"
                                    size="small"
                                    label="FANTASIA"
                                    value={fantasia}
                                    onChange={e => setFantasia(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Input
                                    id="proprietario"
                                    size="small"
                                    label="PROPRIETARIO"
                                    value={proprietario}
                                    onChange={e => setProprietario(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="endereco"
                                    size="small"
                                    label="ENDEREÇO"
                                    value={endereco}
                                    onChange={e => setEndereco(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Input
                                    id="numero"
                                    size="small"
                                    label="NUMERO"
                                    value={numero}
                                    onChange={e => setNumero(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Input
                                    id="bairro"
                                    size="small"
                                    label="BAIRRO"
                                    value={bairro}
                                    onChange={e => setBairro(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Input
                                    id="complemento"
                                    size="small"
                                    label="COMPLEMENTO"
                                    value={complemento}
                                    onChange={e => setComplemento(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input
                                    id="cep"
                                    size="small"
                                    label="CEP"
                                    value={cep}
                                    onChange={e => setCep(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <SelectValueForLabel
                                    label="UF"
                                    value={uf}
                                    setValue={setUf}
                                    options={arrayUfCode}
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AutoCompleteCity
                                    codigouf={uf}
                                    value={cidade}
                                    setValue={setCidade}
                                    size="small"
                                    variant="outlined"
                                    next="telefone1"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input
                                    id="telefone1"
                                    size="small"
                                    label="TELEFONE (1)"
                                    value={telefone1}
                                    onChange={e => maskFone(e, setTelefone1)}
                                    next="telefone2"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input
                                    id="telefone2"
                                    size="small"
                                    label="TELEFONE (2)"
                                    value={telefone2}
                                    onChange={e => maskFone(e, setTelefone2)}
                                    next="email"
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="email"
                                    type="email"
                                    size="small"
                                    label="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input
                                    id="usuario"
                                    size="small"
                                    label="USUARIO"
                                    value={usuario}
                                    onChange={e => setUsuario(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input
                                    id="senha"
                                    size="small"
                                    type="password"
                                    label="SENHA"
                                    value={senha}
                                    onChange={e => setSenha(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={false} sm={1} />
                
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleCadastro()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Template>
    );
}