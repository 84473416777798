import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles";

const SelectValueForLabel = ({ label, value, setValue, options, ...rest }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    border: `2px solid #000`,
                },
                "& select": {
                    fontWeight: 'bold',
                }
            }
        }
    }));

    const classes = useStyles();

    return(
        <FormControl {...rest} fullWidth classes={{root: classes.root}}>
            <InputLabel htmlFor="outlined-age-native-simple" shrink style={{ background: '#fff' }} >{ label }</InputLabel>
            <Select
                native
                value={value}
                onChange={(e) => setValue(e.target.value)}
                label={label}
                inputProps={{ name: label }}
            >
                {options.map((item, index) => (
                    <option value={item.value} key={index}>{item.label}</option>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectValueForLabel;