import Swal from 'sweetalert2';

export const AlertInfo = (icon, title, text) => {
    return Swal.fire({
        icon,
        title,
        text,
        confirmButtonColor: '#d33',
    })
}

export const AlertConfirm = (icon, title, text, yesButton) => {
    return Swal.fire({
        icon,
        title,
        text,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#ccc',
        confirmButtonText: yesButton,
        cancelButtonText: 'Cancelar'
    })
}

export const AlertQuestion = (title, txt, inputValue) => {
    return Swal.fire({
        title,
        input: 'text',
        inputLabel: txt,
        inputValue: inputValue,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        inputValidator: (value) => {
            if (!value) {
                return 'Justificativa invalida'
            }
        }
    })
}