export const isColor = (cod) => {
    let color = 'red';
    if(Number(cod) === 1) color = 'green';
    const style = {
        width: '15px',
        height: '15px',
        borderRadius: '50px',
        backgroundColor: color,
    };
    return(
        <div style={style}></div>
    );
}