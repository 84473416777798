import { useState } from 'react';
import { FaKey } from 'react-icons/fa';
import md5 from 'md5';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Template from '../../components/Template/Template';
import Input from '../../components/Inputs/Input';
import { AlertInfo } from '../../components/Alert/Alert';
import './contrachave.css';

export default function ContraChave() {
    const [chave, setChave] = useState('');
    const [contraChave, setContrachave] = useState('');

    function generateKey() {
        if(chave.length === 6){
            const keyMd5 = md5(chave);
            const arrKeyMd5 = keyMd5.split('');
            const number = /^[0-9]+$/;
            const arrFilterNumber = arrKeyMd5.filter(digito => digito.match(number));
            const counterKey = arrFilterNumber.join('');
            setContrachave(counterKey.substring(0, 6));
        }else{
            AlertInfo('error', 'CHAVE INVALIDA');
        }
    }

    return(
        <Template menuActive="contrachave">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h1 style={{textAlign:'center'}}>CONTRA CHAVE DO SISTEMA</h1>
                </Grid>
                <Grid xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Input 
                        label="INSIRA A CHAVE"
                        value={chave}
                        onChange={e => setChave(e.target.value)}
                        size="small"
                        style={{textAlign:'center'}}
                    />
                </Grid>
                <Grid xs={false} sm={4}></Grid>
                <Grid xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => generateKey()}
                        fullWidth
                    >
                        <FaKey /> &nbsp; GERAR
                    </Button>
                </Grid>
                <Grid xs={false} sm={4}></Grid>
                <Grid xs={false} sm={4}></Grid>
                {contraChave.length > 0 && (
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={3}>
                            <div className="contraChaveContainer">
                                {contraChave}
                            </div>
                        </Paper>
                    </Grid>
                )}
                <Grid xs={false} sm={4}></Grid>
            </Grid>
        </Template>
    );
}