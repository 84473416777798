import moment from 'moment';

export const dateUsd = (data = new Date()) => {
    const today = moment(data).format('YYYY-MM-DD');
    return today;
}

export const dateBrl = (data = new Date()) => {
    const today = moment(data).format('DD/MM/YYYY');
    return today === 'Invalid date' ? '' : today;
}

export const dateBrlHrs = (data = new Date()) => {
    const today = moment(data).format('DD/MM/YYYY HH:mm:ss');
    return today === 'Invalid date' ? '' : today;
}

export const displayDataAmericToday = () => {
    return moment().format('YYYY-MM-DD');
}

export const addMountfromDate = (inicialData, index) => {
    const data = moment(inicialData).add('month', index).format('YYYY-MM-DD');
    return data;
}

export const montData = (day) => {
    const data = moment().date(day).format('YYYY-MM-DD');
    return data;
}

export const startMes = (dataMes = new Date()) => {
    const data = moment(dataMes).startOf('month');
    const dataFormat = dateUsd(data);
    return dataFormat;
}

export const endMes = (dataMes = new Date()) => {
    const data = moment(dataMes).endOf('month');
    const dataFormat = dateUsd(data);
    return dataFormat;
}

export const mesAno = (data = new Date()) => moment(data).format('YYYY-MM');

export const isValidDate = (data) => moment(data).isValid();