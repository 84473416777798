import { useState } from "react";
import { FaWindows, FaGlobeAmericas, FaDownload } from 'react-icons/fa';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Template from "../../components/Template/Template";
import TableData from "../../components/Table/TableData";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { removeMask } from '../../services/mask';
import { spededURL, speed } from '../../services/api';
import './xml.css';

export default function DetalhesXml(props) {
    const { infoXml } = props.location.state;
    const [infoEmpresa] = useState(infoXml.company);
    const [openLoad, setOpenLoad] = useState(false);
    const [rows] = useState([...infoXml.nfe, ...infoXml.nfce]);
    const [columns] = useState([
        {id:'file', label:'NOTA FISCAL'},
        {id:'type', label:'MODELO'},
        {id:'data', label:'DATA'}
    ]);

    function handlePdf(xml) {
        const cnpj = removeMask(infoXml.company.cnpj);
        const file = String(xml.file).split('.');
        const url = `${spededURL}/${xml.type}/danfe/${infoXml.dataMes}-01/${cnpj}/1/${file[0]}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: url})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(url, '_blank');
        }
    }

    async function downloadXmlCompany() {
        setOpenLoad(true);
        const splitData = String(infoXml.dataMes).split('-');
        const data = qs.stringify({
            cnpj: removeMask(infoXml.company.cnpj),
            razao: infoXml.company.razao,
            data: `${splitData[1]}-${splitData[0]}`,
        });
        const response = await speed.post(`/xml/createzipFileLoteXml`, data);
        console.log(response);
        if(response && response.status === 200){
            if(response.data && response.data.filename){
                const url = `${spededURL}/xml/baixarLoteXml/${response.data.filename}`;
                window.open(url, '_blank');
            }
        }
        setTimeout(() => {
            setOpenLoad(false);
        }, 2000);
    }

    return(
        <Template menuActive="xml">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{padding: 10}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <span>RAZÂO: {infoEmpresa.razao}</span><br />
                                <span>FANTASIA: {infoEmpresa.cnpj}</span><br />
                                <span>CNPJ: {infoEmpresa.cnpj} | IE: {infoEmpresa.ie}</span><br />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <span>ENDEREÇO: {infoEmpresa.endereco}</span><br />
                                <span>CIDADE: {infoEmpresa.cidade}/{infoEmpresa.uf}</span><br />
                                <span>TELEFONE: {infoEmpresa.telefone}</span><br />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {infoEmpresa.situacao ? <FaWindows size={30} /> : <FaGlobeAmericas size={30} />}
                </Grid>
                <Grid item xs={false} sm={7} />
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => downloadXmlCompany()}
                        fullWidth
                    >
                        <FaDownload /> &nbsp; BAIXAR
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableData
                        columns={columns}
                        rows={rows}
                        clickFunction={handlePdf}
                        vh="60vh"
                    />
                </Grid>
            </Grid>
        </Template>
    );
}