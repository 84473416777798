import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function MenuFluter({ anchorEl, setAnchorEl, arrMenu }) {

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {arrMenu.map((item, index) => {
                const Icon = item.icon;
                const funcItem = item.func;
                return(
                    <MenuItem key={index} onClick={() => funcItem()}>
                        <ListItemIcon>
                            <Icon />
                        </ListItemIcon>
                        <ListItemText primary={item.txt} />
                    </MenuItem>
                );
            })}
        </Menu>
  );
}