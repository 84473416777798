import axios from 'axios';

// export const backendURL = 'http://localhost/acesso';
// export const spededURL = 'http://192.168.0.108/apispeednfe';

export const backendURL = 'https://www.sistemarenove.com/acesso';
export const spededURL = 'https://www.sistemarenove.com/apispeed';
export const arURL = 'https://www.sistemarenove.com/ar/main';

export const emissorUrl = 'https://www.sistemarenove.com/backend';


export const urlCalbackSafe2Pay = 'https://www.sistemarenove.com/acesso/safe2pay/sistemaLocal';

const api = axios.create({
    baseURL: backendURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export const apiEmissor = axios.create({
    baseURL: emissorUrl,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export const speed = axios.create({
    baseURL: spededURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export const consultaCnpj = axios.create({
    baseURL: `https://publica.cnpj.ws/cnpj`
});

export const safe2pay = axios.create({
    baseURL: 'https://apinode.renovetecnologia.com',
});

export const apisafe2pay = axios.create({
    baseURL: 'https://apinode.renovetecnologia.com',
});

export const apiServicesSafe2Pay = axios.create({
    baseURL: 'https://apinode.renovetecnologia.com',
});

export const directSafe2Pay = axios.create({
    baseURL: "https://api.safe2pay.com.br/v2/Transaction",
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "X-API-KEY": "1629FAD5D8BA42E78FC8D11B49CBBA18"
    }
});

export const ar = axios.create({
    baseURL: arURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export const nodeBackupRenove = axios.create({
    baseURL: "http://191.101.71.149:3000",
});

export const logout = () => {
    sessionStorage.removeItem('infoLogin');
    sessionStorage.removeItem('jwtRenove');
    window.location.reload();
}

export default api;