import { createMuiTheme } from '@material-ui/core/styles';

const colorTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#a71a1a",
        },
        secondary: {
            main: "#1e2b30"
        },
        grey: {
            main: "#6bbd5b"
        }
    },
});

export default colorTheme;