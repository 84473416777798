import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFolderOpen, FaChevronCircleUp, FaChevronCircleDown, FaWallet, FaLaptop, FaUserCheck } from 'react-icons/fa';
import { FaTachometerAlt, FaUsers, FaBuilding, FaFileArchive, FaKey, FaDollarSign, FaCashRegister, FaDownload } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { SideArea, HeaderMenu, MenuItem, LinkMenu, ContainerSideArea } from './Styled';

const SideBar = ({ left, menuActive }) => {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [permissao] = useState(infoLogin.permissao.split(','));
    const [subMenu, setSubMenu] = useState(sessionStorage.getItem('subMenu') || null);

    const colapseMenu = (expand) => {
        setSubMenu((value) => {
            if(value === expand) {
                sessionStorage.setItem('subMenu', '');
                return null;
            }else{
                sessionStorage.setItem('subMenu', expand);
                return expand;
            }
        });
    }

    const isPermiteMenu = (cod = 0) => {
        const isPermite = permissao.filter(p => Number(p) === Number(cod));
        if(isPermite.length === 1) return true;
        return false;
    }

    return (
        <SideArea left={left}>
            <ContainerSideArea>
                <div>
                    <HeaderMenu />
                    <Link to="/">
                        <MenuItem active={(menuActive === 'home') ? true : false}>
                            <LinkMenu>
                                <FaTachometerAlt />
                                <Typography component="h6">INICIO</Typography>
                            </LinkMenu>
                        </MenuItem>
                    </Link>

                    {(isPermiteMenu(1) || isPermiteMenu(8) || isPermiteMenu(2)) && (
                        <MenuItem onClick={() => colapseMenu('cadastros')}>
                            <LinkMenu>
                                <FaFolderOpen/>
                                <Typography component="h6">CADASTROS</Typography>
                            </LinkMenu>
                            {(subMenu === 'cadastros') ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                        </MenuItem>
                    )}
                    <Collapse in={(subMenu ===  'cadastros') ? true : false}>
                        {isPermiteMenu(8) && (
                            <Link to="/contadores">
                                <MenuItem active={(menuActive === 'contadores') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaUsers />
                                        <Typography component="h6">CONTADORES</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                        {isPermiteMenu(1) && (
                            <Link to="/revenda">
                                <MenuItem active={(menuActive === 'revenda') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaUserCheck />
                                        <Typography component="h6">REVENDA</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                        {isPermiteMenu(2) && (
                            <Link to="/empresas">
                                <MenuItem active={(menuActive === 'empresas') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaBuilding />
                                        <Typography component="h6">EMPRESAS</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                    </Collapse>

                    {(isPermiteMenu(5) || isPermiteMenu(6)) && (
                        <MenuItem onClick={() => colapseMenu('financeiro')}>
                            <LinkMenu>
                                <FaWallet/>
                                <Typography component="h6">FINANCEIRO</Typography>
                            </LinkMenu>
                            {(subMenu === 'financeiro') ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                        </MenuItem>
                    )}
                    <Collapse in={(subMenu ===  'financeiro') ? true : false}>
                        {isPermiteMenu(5) && (
                            <Link to="/financeiro">
                                <MenuItem active={(menuActive === 'financeiro') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaDollarSign />
                                        <Typography component="h6">COBRANÇAS</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                        {isPermiteMenu(6) && (
                            <Link to="/caixa">
                                <MenuItem active={(menuActive === 'caixa') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaCashRegister />
                                        <Typography component="h6">CAIXA</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                    </Collapse>

                    {isPermiteMenu(3) && (
                        <Link to="/xml">
                            <MenuItem active={(menuActive === 'xml') ? true : false}>
                                <LinkMenu>
                                    <FaFileArchive />
                                    <Typography component="h6">XML</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                    )}

                    {(isPermiteMenu(4) || isPermiteMenu(7)) && (
                        <MenuItem onClick={() => colapseMenu('sistema')}>
                            <LinkMenu>
                                <FaLaptop/>
                                <Typography component="h6">SISTEMA</Typography>
                            </LinkMenu>
                            {(subMenu === 'sistema') ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                        </MenuItem>
                    )}

                    <Collapse in={(subMenu ===  'sistema') ? true : false}>
                        {isPermiteMenu(4) && (
                            <Link to="/contrachave">
                                <MenuItem active={(menuActive === 'contrachave') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaKey />
                                        <Typography component="h6">CONTRA CHAVE</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                        {isPermiteMenu(7) && (
                            <Link to="/downloads">
                                <MenuItem active={(menuActive === 'downloads') ? true : false} subMenu>
                                    <LinkMenu>
                                        <FaDownload />
                                        <Typography component="h6">DOWNLOADS</Typography>
                                    </LinkMenu>
                                </MenuItem>
                            </Link>
                        )}
                    </Collapse>
                </div>
            </ContainerSideArea>  
        </SideArea>
    );
}

export default SideBar;