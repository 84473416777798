import React, { useState, Fragment, useEffect } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SelectSimple from '../Selects/SelectSimple';
import api, { apiEmissor } from '../../services/api';
import { dateBrl, startMes, endMes } from '../../services/date';
import { removeMask } from '../../services/displayValue';
import { Primary, Secondary } from './style';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        marginRight: 5,
        marginLeft: -5,
    }
});

export default function DrawerBuscaContas({ state, setState, setRows, setTxt, openLoad, origem }) {
    const classes = useStyles();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [filtro, setFiltro] = useState("MÊS");
    const [cliente, setCliente] = useState('');
    const [inicio, setInicio] = useState('');
    const [final, setFinal] = useState('');
    const [dataMes, setDataMes] = useState('');
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        setCliente('');
        setInicio('');
        setFinal('');
    }, [filtro]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function filterEmpresa(emp) {
        const { fantasia = '', razao = '', cnpj = '', cpf = '', id = '' } = emp;
        const busca = cliente.toUpperCase();
        if(Number(id) === Number(busca)) return emp;
        else if(String(fantasia).includes(busca)) return emp;
        else if(String(razao).includes(busca)) return emp;
        else if(removeMask(cnpj).includes(removeMask(busca))) return emp;
        else if(removeMask(cpf).includes(removeMask(busca))) return emp;
    }

    async function getContasByIdEmpresa(id_empresa) {
        openLoad(true);
        setState(false);
        const url = `/financeiro/listAcountCompany/${infoLogin.id}/${jwt}/${id_empresa}`;
        const response = await api.get(url);
        console.log(response);
        if(response && response.status === 200 && response.data){
            setRows(response.data);
        }
        setEmpresas([]);
        setTxt(cliente.toUpperCase());
        openLoad(false);
    }

    async function buscar() {
        openLoad(true);
        if(filtro === "EMPRESA"){
            if(String(cliente).length > 2){
                const response = await api.get(`/empresas/listAll/${infoLogin.id}/${jwt}`);
                console.log(response);
                if(response && response.status === 200 && response.data){
                    let allEmpresas = response.data;
                    if(Number(infoLogin.revenda) === 1){
                        allEmpresas = allEmpresas.filter(emp => Number(emp.revenda) === Number(infoLogin.id));
                    }
                    console.log(allEmpresas);
                    if(allEmpresas.length > 0){
                        const empresa = allEmpresas.filter(emp => filterEmpresa(emp));
                        if(empresas.length === 0) openLoad(false);
                        else if(empresa.length === 1) getContasByIdEmpresa(empresa[0].id);
                        else setEmpresas(empresa);
                    }else{
                        openLoad(false);
                    }
                }
            }
        }else{
            setState(false);
            const start = (filtro === "MÊS")?startMes(dataMes):inicio;
            const end = (filtro === "MÊS")?endMes(dataMes):final;
            if(origem === 2) loadData(start, end);
            else if(origem === 1) loadSite(start, end);
            setTxt(`De ${dateBrl(start)} à ${dateBrl(end)}`);
        }
    }

    async function loadData(start, end) {
        const url = `/financeiro/loadContas/${infoLogin.id}/${jwt}/${start}/${end}`;
        const response = await api.get(url);
        console.log(response);
        if(response.status === 200 && response.data){
            setRows(response.data);
        }
        openLoad(false);
    }

    async function loadSite(start, end) {
        const response = await apiEmissor.get(`/pagamentos/listPagamentosByVencimento/${start}/${end}`);
        console.log(response);
        if(response && response.status === 200 && response.data){
            let registers = response.data;
            if(registers.length > 0){
                if(Number(infoLogin.revenda) > 0){
                    registers = registers.filter(i => Number(i.revenda) === Number(infoLogin.cod_site));
                }
                const formatRegisters = registers.map(item => ({
                    ...item, valor_boleto: item.valor_sistema, liquido: item.valor_liquido,
                    status_parcela: item.status_pagamento, recebido: 0
                }));
                setRows(formatRegisters);
            }
        }
        openLoad(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="BUSCAR" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                <ListItem>
                    <SelectSimple
                        label="FILTRO"
                        value={filtro}
                        setValue={setFiltro}
                        options={["MÊS", "PERIODO", "EMPRESA"]}
                    />
                </ListItem>

                {filtro === "EMPRESA" && (
                    <ListItem>
                        <TextField 
                            fullWidth
                            label="EMPRESA" 
                            variant="outlined"
                            value={cliente}
                            onChange={(e) => setCliente(e.target.value)}
                        />
                    </ListItem>
                )}

                {filtro === "MÊS" && (
                    <ListItem>
                        <TextField 
                            fullWidth
                            type="month"
                            label="MÊS" 
                            variant="outlined"
                            value={dataMes}
                            onChange={(e) => setDataMes(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                )}

                {filtro === "PERIODO" && (
                    <>
                        <ListItem>
                            <TextField 
                                fullWidth
                                type="date"
                                label="DATA INICIAL" 
                                variant="outlined" 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={inicio}
                                onChange={(e) => setInicio(e.target.value)}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField 
                                fullWidth
                                type="date"
                                label="DATA FINAL" 
                                variant="outlined" 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={final}
                                onChange={(e) => setFinal(e.target.value)}
                            />
                        </ListItem>
                    </>
                )}

                {empresas.map(emp => (
                    <ListItem style={{cursor:'pointer'}} onClick={() => getContasByIdEmpresa(emp.id)}>
                        <ListItemText disableTypography>
                            <Primary>{ emp.fantasia }</Primary>
                            <Secondary>{ emp.cidade }</Secondary>
                        </ListItemText>
                    </ListItem>
                ))}

                <ListItem>
                    <Button 
                        onClick={() => buscar()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="btn"
                    >
                        <FaSearch className={classes.icon} />
                        BUSCAR
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
