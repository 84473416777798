import { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import moment from 'moment/moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import Input from '../../components/Inputs/Input';
import SelectValueForLabel from '../../components/Selects/SelectValueForLabel';
import AutoCompleteCity from '../../components/Selects/AutoCompleteCity';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import TableData from '../../components/Table/TableData';
import { AlertInfo, AlertConfirm } from '../../components/Alert/Alert';
import { arrayUfCode } from '../../data/options';
import { maskCPF, maskCNPJ, maskNumber, maskCEP, maskFone, maskDinheiro, removeMask } from '../../services/mask';
import { displayMoney, typeSafe2Pay } from '../../services/displayValue';
import { dateBrl, dateBrlHrs, dateUsd } from '../../services/date';
import { apiEmissor } from '../../services/api';

export default function CadastroEmpresaSite(props) {
    const { infoEmpresa } = props.location.state;
    const [openLoad, setOpenLoad] = useState(false);
    const [id] = useState(infoEmpresa.id || null);
    const [razao, setRazao] = useState(infoEmpresa.razao || "");
    const [fantasia, setFantasia] = useState(infoEmpresa.fantasia || "");
    const [cnpj, setCnpj] = useState(infoEmpresa.cnpj || "");
    const [ie, setIe] = useState(infoEmpresa.ie || "");
    const [responsavel, setResponsavel] = useState(infoEmpresa.responsavel || "");
    const [cpf, setCpf] = useState(infoEmpresa.cpf || "");
    const [endereco, setEndereco] = useState(infoEmpresa.endereco || "");
    const [bairro, setBairro] = useState(infoEmpresa.bairro || "");
    const [numero, setNumero] = useState(infoEmpresa.numero || 0);
    const [complemento, setComplemento] = useState(infoEmpresa.complemento || "");
    const [cep, setCep] = useState(infoEmpresa.cep || "");
    const [arrayUf] = useState(arrayUfCode);
    const [codigouf, setCodigoUf] = useState(infoEmpresa.codigouf || "15");
    const [cidade, setCidade] = useState(infoEmpresa.codigomunicipio || "");
    const [telefone, setTelefone] = useState(infoEmpresa.telefone || "");
    const [email, setEmail] = useState(infoEmpresa.email || "");
    const [acesso, setAcesso] = useState(infoEmpresa.acesso || 1);
    const [valor_sistema, setValorSistema] = useState(infoEmpresa.valor_sistema || "");
    const [limite, setLimite] = useState(infoEmpresa.limite || "");
    const [data_adessao] = useState(dateUsd(infoEmpresa.createdata) || dateUsd());
    const [data_ativacao, setDataAtivacao] = useState(dateUsd(infoEmpresa.data_ativacao) || dateUsd());
    const [data_expiracao, setDataExpiracao] = useState(infoEmpresa.data_expiracao || dateUsd());
    const [isPage, setPage] = useState(0);
    const [pagamentos, setPagamentos] = useState([]);
    const [logs] = useState([]);
    const history = useHistory();
    const [columns] = useState([
        { id: 'id_transaction', label: 'COD', minWidth: 40 },
        { id: 'type_transaction', label: 'TIPO', minWidth: 40, format: typeSafe2Pay },
        { id: 'create_transaction', label: 'EMISSÃO', minWidth: 50, format: dateBrl },
        { id: 'vencimento', label: 'VENCIMENTO', minWidth: 50, format: dateBrl },
        { id: 'data_pagamento', label: 'PAGAMENTO', minWidth: 50, format: dateBrl },
        { id: 'valor_sistema', label: 'VALOR', minWidth: 50, format: displayMoney, align: 'right' },
        { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
    ]);

    async function loadFinancas() {
        if(id){
            setOpenLoad(true);
            const resp = await apiEmissor.get(`/pagamentos/listPagamentos/${id}`);
            console.log(resp);
            if(resp && resp.status === 200) setPagamentos(resp.data);
            setOpenLoad(false);
        }
    }

    async function loadHistory() {
        // if(id){
        //     setOpenLoad(true);            
        //     const res = await api.get(`/empresas/listLogs/${id}`);
        //     if(res && res.status === 200) setLogs(res.data);
        //     setOpenLoad(false);
        // }
    }

    function filterParcela(infoParcela, status) {
        if(status === 1){
            if(Number(infoParcela.status_pagamento) === 1 && moment(dateUsd()).isAfter(infoParcela.vencimento)){
                return infoParcela;
            }
        }else if(status === 2){
            if(Number(infoParcela.status_pagamento) === 3){
                return infoParcela;
            }
        }else if(status === 4){
            if(Number(infoParcela.status_pagamento) === 1 && moment(dateUsd()).isBefore(infoParcela.vencimento)){
                return infoParcela;
            }
        }else if(status === 5){
            if(Number(infoParcela.status_pagamento) === 1 && dateUsd() === infoParcela.vencimento){
                return infoParcela;
            }
        }else if(status === 6){
            if(Number(infoParcela.status_pagamento) === 2){
                return infoParcela;
            }
        }else if(status === 7){
            // if(Number(infoParcela.status_pagamento) === 7 && Number(infoParcela.recebido) === 0){
            //     return infoParcela;
            // }
        }
    }

    const isColor = (row) => {
        let color = '#000';
        if(filterParcela(row, 1)) color = "#a71a1a";
        else if(filterParcela(row, 2)) color = "#6bbd5b";
        else if(filterParcela(row, 3)) color = "#248fb2";
        else if(filterParcela(row, 5)) color = "#4d524e";
        else if(filterParcela(row, 6)) color = "#004805";
        else if(filterParcela(row, 7)) color = "#3c0263";
        return color;
    }

    const handleDrawer = (info) => {
        // setInfoDrawer(info);
        // setDrawerInfo(true);
    }

    const handleSituacao = () => {
        if(Number(acesso) === 1) setAcesso(2);
        else setAcesso(1);
    }

    async function handleSubmit() {
        setOpenLoad(true);
        const data = {
            id,
            razao: razao.toUpperCase(),
            fantasia: fantasia.toUpperCase(),
            cnpj,
            ie: removeMask(ie),
            responsavel: responsavel.toUpperCase(),
            cpf,
            endereco: endereco.toUpperCase(),
            bairro: bairro.toUpperCase(),
            numero: numero.toUpperCase(),
            complemento: complemento.toUpperCase(),
            cep,
            codigouf,
            uf: (cidade && cidade.cidades) ? cidade.uf : 'PA',
            cidade: (cidade && cidade.cidades) ? cidade.cidades : '',
            codigomunicipio: (cidade && cidade.municipio) ? cidade.municipio : '',
            telefone,
            email: email.toUpperCase(),
            cpf_cnpj_contador: infoEmpresa.cpf_cnpj_contador,
            valor_sistema,
        }
        
        const response = await apiEmissor.post(`/empresas/updateEmpresa`, qs.stringify(data));
        console.log(response);
        if(response && response.status === 200){
            const dataResp = { 
                id_empresa: id, acesso, 
                data_ativacao: (Number(infoEmpresa.acesso) === 2 && Number(acesso) === 1) ? dateUsd() : data_ativacao, 
                data_expiracao, limite, valor_sistema
            };
            const resp = await apiEmissor.post(`/empresas/updateConfigCompanyByPainel`, qs.stringify(dataResp));
            console.log(resp);
            AlertInfo('success', 'DADOS GRAVADOS!').then(() => {
                history.push('/empresas');
            });
        }
        setOpenLoad(false);
    }

    function inativeCompany() {
        AlertConfirm('', 'DELETAR EMPRESA DOS REGISTROS', '', 'DELETAR').then(async resp => {
            if(resp.isConfirmed){
                setOpenLoad(true);
                const dataResp = { id_empresa: id, acesso: 0 };
                const response  = await apiEmissor.post(`/empresas/control`, qs.stringify(dataResp));
                console.log(response);
                if(response && response.status === 200){
                    AlertInfo('success', 'DADOS GRAVADOS!').then(() => {
                        history.push('/empresas');
                    });
                }
                setOpenLoad(true);
            }
        });
    }
    
    useEffect(() => {
        if(isPage === 1) loadFinancas();
        else if(isPage === 2) loadHistory();
        // eslint-disable-next-line
    }, [isPage]);

    return(
        <Template menuActive="empresas">
            <Paper square style={{marginBottom: 8}}>
                <Tabs
                    value={isPage}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => setPage(newValue)}
                    aria-label="disabled tabs example"
                    centered
                >
                    <Tab label="DADOS" />
                    <Tab label="PAGAMENTO" />
                    <Tab label="HISTORICO" />
                </Tabs>
            </Paper>
            
            {isPage === 0 && (
                <Grid container spacing={1}>  
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{padding: 10}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    {id  && (
                                        <Input 
                                            size="small"
                                            label="ID EMPRESA"  
                                            value={id}
                                            onChange={() => {}}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={false} sm={6}></Grid>
                                <Grid item xs={12} sm={3}>
                                    {id  && (
                                        <Button  
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                            onClick={() => inativeCompany()}
                                        > 
                                            <FaTrash />&nbsp; DELETAR
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="RAZÃO SOCIAL"  
                                        value={razao}
                                        onChange={(e) => setRazao(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="FANTASIA"  
                                        value={fantasia}
                                        onChange={(e) => setFantasia(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="CNPJ"  
                                        value={cnpj}
                                        onChange={(e) => maskCNPJ(e, setCnpj)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="INSCRIÇÃO ESTADUAL"  
                                        value={ie}
                                        onChange={(e) => maskNumber(e, setIe)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="RESPONSAVEL"  
                                        value={responsavel}
                                        onChange={(e) => setResponsavel(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="CPF DO RESPONSAVEL"  
                                        value={cpf}
                                        onChange={(e) => maskCPF(e, setCpf)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input 
                                        size="small"
                                        label="ENDEREÇO"  
                                        value={endereco}
                                        onChange={(e) => setEndereco(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        size="small"
                                        label="BAIRRO"  
                                        value={bairro}
                                        onChange={(e) => setBairro(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Input 
                                        size="small"
                                        label="NUMERO"  
                                        value={numero}
                                        onChange={(e) => maskNumber(e, setNumero)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input 
                                        size="small"
                                        label="COMPLEMENTO"  
                                        value={complemento}
                                        onChange={(e) => setComplemento(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <SelectValueForLabel 
                                        label="UF"
                                        value={codigouf}
                                        setValue={setCodigoUf}
                                        options={arrayUf} 
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AutoCompleteCity 
                                        codigouf={codigouf} 
                                        value={cidade}
                                        setValue={setCidade} 
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input 
                                        size="small"
                                        label="CEP"  
                                        value={cep}
                                        onChange={(e) => maskCEP(e, setCep)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input 
                                        size="small"
                                        label="EMAIL"  
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input 
                                        size="small"
                                        label="TELEFONE"  
                                        value={telefone}
                                        onChange={(e) => maskFone(e, setTelefone)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        type="date" 
                                        label="DATA ADESSÃO"
                                        value={data_adessao}
                                        onChange={() => {}}
                                        disabled
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        type="date"
                                        label="DATA ATIVAÇÃO"
                                        value={data_ativacao}
                                        onChange={e => setDataAtivacao(e.target.value)}
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input 
                                        type="date"
                                        size="small"
                                        label="DATA EXPIRAÇÃO"  
                                        value={data_expiracao}
                                        onChange={e => setDataExpiracao(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input 
                                        type="number"
                                        size="small"
                                        label="VALOR SUPORTE"  
                                        value={valor_sistema}
                                        onChange={(e) => maskDinheiro(e, setValorSistema)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                <Input 
                                        type="number"
                                        size="small"
                                        label="LIMITADO"  
                                        value={limite}
                                        onChange={(e) => maskDinheiro(e, setLimite)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}></Grid>
                                <Grid item xs={12} sm={3}></Grid>
                                
                                <Grid item xs={12} sm={3}>
                                    {id  && (
                                        <Button  
                                            variant="contained" 
                                            color={Number(acesso) === 2 ? 'primary' : ''}
                                            className={Number(acesso) === 1 ? 'successColor' : ''}
                                            fullWidth
                                            onClick={handleSituacao}
                                        > 
                                            {Number(acesso) === 1 ? 'ATIVO' : 'BLOQUEADO'}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button  
                            variant="contained" 
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                        > 
                            SALVAR
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    <SimpleBackdrop open={openLoad} />
                </Grid>
            )}
            {isPage === 1 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12}>
                        <TableData
                            columns={columns}
                            rows={pagamentos}
                            clickFunction={handleDrawer}
                            isColor={isColor}
                            vh="60vh"
                        />
                    </Grid>
                </Grid>
            )}

            {isPage === 2 && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TableData
                            columns={[
                                { id: 'data_log', label: 'DATA', minWidth: 50, format: dateBrlHrs },
                                { id: 'log', label: 'LOG', minWidth: 170 },
                            ]}
                            rows={logs}
                            clickFunction={() => {}}
                            vh="60vh"
                        />
                    </Grid>
                </Grid>
            )}
        </Template>
    );
}