import { useState, useEffect } from 'react';
import Template from '../../components/Template/Template';

import DashboardContabil from './DashboardContabil';
import DashboardRevenda from './DashboardRevenda';

export default function Home() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));

    useEffect(() => {
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({stopLoad: true})
            window.ReactNativeWebView.postMessage(dataPost);
        }
    }, []);

    return(
        <Template menuActive="home">
            {Number(infoLogin.contador) === 1 && Number(infoLogin.revenda) === 0 && (
                <DashboardContabil />
            )}

            {Number(infoLogin.revenda) === 1 &&  (
                <DashboardRevenda />
            )}
        </Template>
    );
}