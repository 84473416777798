import CadastroContador from '../Contador/CadastroContador';
import CadastroRevenda from '../Revenda/CadastroRevenda';

export default function CadastroUsuario() {
    const infoLogin = JSON.parse(sessionStorage.getItem('infoLogin')) || null;

    return(
        <>
            {Number(infoLogin.revenda) === 1 && (
                <CadastroRevenda location={{state: {id_revenda: infoLogin.id}}} />
            )}
            {Number(infoLogin.revenda) === 0 && Number(infoLogin.contador) === 1 && (
                <CadastroContador location={{state: {id_contador: infoLogin.id}}} />
            )}
        </>
    );
}