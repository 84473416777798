import React, { useState, useEffect } from 'react';
import { FaGlobe, FaComputer } from 'react-icons/fa6';
import moment from 'moment';
import md5 from 'md5';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import LanguageIcon from '@material-ui/icons/Language';
import CardDestakIcon from '../../components/Card/CardDestakIcon';
import api, { apiEmissor } from '../../services/api';
import { dateBrl } from '../../services/date';

import './home.css';

export default function DashboardContabil() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [erp, setErp] = useState(0);
    const [site, setSite] = useState(0);
    const [rowsErp, setRowsErp] = useState([]);
    const [rowsSite, setRowsSite] = useState([]);
    const [allListErp, setAllListErp] = useState(5);
    const [allListSite, setAllListSite] = useState(5);

    async function loadDataContabil() {
        const response = await api.get(`/empresas/listAll/${infoLogin.id}/${jwt}`);
        if(response && response.status === 200){
            if(!response.data.invalidJwt){
                const { data } = response;
                const fil = data.filter(emp => Number(emp.contabilidade) === Number(infoLogin.id));
                setErp(fil.length);
                setRowsErp(fil.reverse());
            }
        }
        if(Number(infoLogin.cod_site) > 0){
            const resp = await apiEmissor.get(`/empresas/loadEmpresas/${md5(infoLogin.cod_site)}`);
            if(resp && resp.status === 200 && resp.data){
                const site = resp.data;
                const activeSite = site.filter(emp => emp.cnpj);
                const dataSort = activeSite.sort((a, b) => {
                    const dataA = moment(a.createdata);
                    const dataB = moment(b.createdata);
                    return dataA.isBefore(dataB) ? -1 : dataA.isAfter(dataB) ? 1 : 0;
                });
                console.log(dataSort);
                setSite(activeSite.length);
                setRowsSite(dataSort.reverse());
            }
        }
    }

    function toggleListErp() {
        setAllListErp(allListErp === 5 ? rowsErp.length : 5);
    }

    function toggleListSite() {
        setAllListSite(allListSite === 5 ? rowsSite.length : 5);
    }

    // eslint-disable-next-line
    useEffect(() => loadDataContabil(), []);

    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h2 style={{textAlign:'center'}}>ACESSO RENOVE</h2>
            </Grid>
            <Grid item xs={12} container spacing={3} justify='center'>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaComputer}
                        colorIcon='#1e2b30'
                        title='CLIENTES/ERP'
                        subTitle={erp}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaGlobe}
                        colorIcon='#1e2b30'
                        title='CLIENTES/SITE'
                        subTitle={site}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container spacing={2} justify='center'>
                <Grid item xs={12} sm={5}>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{marginBottom: '-10px', marginLeft: '10px', zIndex: 111}}
                        onClick={toggleListErp}
                    >
                        ULTIMAS ADIÇÕES ERP
                    </Button>
                    <Paper elevation={3} style={{height: '55vh', overflowY: 'auto'}}>
                        <List component="nav">
                        {rowsErp.slice(0, allListErp).map(emp => (
                            <ListItem button>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor:'#6bbd5b'}}>
                                        <LaptopMacIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={emp.fantasia} secondary={dateBrl(emp.data_adessao)} />
                            </ListItem>
                        ))}
                        </List>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{marginBottom: '-10px', marginLeft: '10px', zIndex: 111}}
                        onClick={toggleListSite}
                    >
                        ULTIMAS ADIÇÕES SITE
                    </Button>
                    <Paper elevation={3} style={{height: '55vh', overflowY: 'auto'}}>
                        <List component="nav">
                        {rowsSite.slice(0, allListSite).map(emp => (
                            <ListItem button>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor:'#6bbd5b'}}>
                                        <LanguageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={emp.fantasia} secondary={dateBrl(emp.createdata)} />
                            </ListItem>
                        ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}