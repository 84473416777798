import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import moment from 'moment';
import Input from '../../components/Inputs/Input';
import api from '../../services/api';
import { dateUsd, endMes, startMes, mesAno } from '../../services/date';

export default function GraficoReceber() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [dataRef, setDataRef] = useState(mesAno());
    const [dataGrafico, setDataGrafico] = useState([]);

    async function loadData() {
        const response = await api.get(`/financeiro/loadContas/${infoLogin.id}/${jwt}/${startMes(dataRef)}/${endMes(dataRef)}`);
        if (response && response.status === 200 && response.data) {
            const dataContas = response.data;
            let recebido = 0;
            let atrasada = 0;
            let vencer = 0;
            const dataFormat = dataContas.map(i => {
                if (Number(i.status_parcela) === 3 || Number(i.recebido) === 1) recebido += Number(i.valor_boleto);
                else if (Number(i.status_parcela) === 1 && moment(dateUsd()).isAfter(moment(i.vencimento))) atrasada += Number(i.valor_boleto);
                else if (Number(i.status_parcela) === 1) vencer += Number(i.valor_boleto);
                return i;
            });
            console.log(dataFormat);
            setDataGrafico([
                { name: 'RECEBIDO', value: recebido, color: "#6bbd5b" },
                { name: 'ATRASADO', value: atrasada, color: "#a71a1a" },
                { name: 'A VENCER', value: vencer, color: "#000" },
            ]);
        }
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const calc = Number((percent * 100).toFixed(0));

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {calc > 0 ? `${calc}%` : ``}
            </text>
        );
    };

    // eslint-disable-next-line
    useEffect(() => loadData(), [dataRef]);

    return (
        <div style={{position:'relative'}}>
            <div style={{position: 'absolute', zIndex: 222, width: '100%', padding: '20px 20px'}}>
                <Input 
                    type="month"
                    size="small"
                    label="MÊS"  
                    value={dataRef}
                    onChange={(e) => setDataRef(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <PieChart width={300} height={300} >
                <Pie
                    dataKey="value"
                    data={dataGrafico}
                    innerRadius={30}
                    outerRadius={90}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    cy={180}
                >
                    {dataGrafico.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
        </div>
    );
}