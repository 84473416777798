import React, { useState, Fragment, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SelectValueForLabel from '../Selects/SelectValueForLabel';
import Input from '../Inputs/Input';
import { backendURL, emissorUrl } from '../../services/api';
import { mesAno } from '../../services/date';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        marginRight: 5,
        marginLeft: -5,
    }
});

export default function DrawerRelCobrancas({ state, setState, origem }) {
    const classes = useStyles();
    const [filtro, setFiltro] = useState("0");
    const [mes, setMes]= useState(mesAno());
    const [order, setOrder] = useState("vencimento");
    const [landscape, setLandscape] = useState("L");
    const [url, setUrl] = useState(`${backendURL}/empresas/relCompanys`);
    const [columns, setColumns] = useState([
        { label: "CODIGO", name: 'id', value: false, align: 'left', format: ''},
        { label: "FANTASIA", name: 'fantasia', value: true, align: 'left', format: ''},
        { label: "CNPJ", name: 'cnpj', value: false, align: 'left', format: ''},
        { label: "RAZAO", name: 'razao', value: false, align: 'left', format: ''},
        { label: "CIDADE", name: 'cidade', value: true, align: 'left', format: ''},
        { label: "VALOR", name: 'valor_boleto', value: true, align: 'left', format: ''},
        { label: "VENCIMENTO", name: 'vencimento', value: true, align: 'left', format: 'data'},
        { label: "PAGAMENTO", name: 'data_pagamento', value: true, align: 'left', format: 'data'},
        { label: "STATUS", name: 'message', value: false, align: 'left', format: ''},
        { label: "TIPO", name: 'type_cobranca', value: false, align: 'left', format: ''},
        { label: "ID SAFE2PAY", name: 'id_transaction', value: false, align: 'left', format: ''},
    ]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const handleChange = (e, key) => {
        const newValue = columns.map(item => {
            if(item.name === key) return { ...item, value: e.target.checked };
            return item;
        });
        setColumns(newValue);
    }

    async function viewRel() {
        function addInputForm(value, name, formAdd) {
            var inp = document.createElement("input");
            inp.value = value;
            inp.name = name;
            formAdd.appendChild(inp);
        }
        
        let form = document.createElement("form");
        form.method = "post";
        form.target = "pagina";
        form.action = url;
        form.style.cssText = "display: none;";
        const validColumns = columns.filter(c => c.value);

        addInputForm(filtro, "filtro", form);
        addInputForm(order, "order", form);
        const formatColumns = JSON.stringify(validColumns.map(c => c.name));
        addInputForm(formatColumns, "columns", form);
        addInputForm(landscape, "landscape", form);
        const formatLabels = JSON.stringify(validColumns.map(c => c.label));
        addInputForm(formatLabels, "labels", form);
        addInputForm(JSON.stringify(validColumns), "configs", form);

        document.body.appendChild(form);
        form.submit();
    }

    function alterRel() {
        if(origem === 1){
            setColumns([
                { label: "CODIGO", name: 'id', value: false, align: 'left', format: ''},
                { label: "FANTASIA", name: 'fantasia', value: true, align: 'left', format: ''},
                { label: "CNPJ", name: 'cnpj', value: false, align: 'left', format: ''},
                { label: "RAZAO", name: 'razao', value: false, align: 'left', format: ''},
                { label: "CIDADE", name: 'cidade', value: true, align: 'left', format: ''},
                { label: "VALOR", name: 'valor_sistema', value: true, align: 'left', format: ''},
                { label: "VENCIMENTO", name: 'vencimento', value: true, align: 'left', format: 'data'},
                { label: "PAGAMENTO", name: 'data_pagamento', value: true, align: 'left', format: 'data'},
                { label: "STATUS", name: 'message', value: false, align: 'left', format: ''},
                { label: "TIPO", name: 'type_cobranca', value: false, align: 'left', format: ''},
                { label: "ID SAFE2PAY", name: 'id_transaction', value: false, align: 'left', format: ''},
            ]);
            setUrl(`${emissorUrl}/empresas/relCompanys`);
        }else if(origem === 2){
            setColumns([
                { label: "CODIGO", name: 'id', value: false, align: 'left', format: ''},
                { label: "FANTASIA", name: 'fantasia', value: true, align: 'left', format: ''},
                { label: "CNPJ", name: 'cnpj', value: false, align: 'left', format: ''},
                { label: "RAZAO", name: 'razao', value: false, align: 'left', format: ''},
                { label: "CIDADE", name: 'cidade', value: true, align: 'left', format: ''},
                { label: "VALOR", name: 'valor_boleto', value: true, align: 'left', format: ''},
                { label: "VENCIMENTO", name: 'vencimento', value: true, align: 'left', format: 'data'},
                { label: "PAGAMENTO", name: 'data_pagamento', value: true, align: 'left', format: 'data'},
                { label: "STATUS", name: 'message', value: false, align: 'left', format: ''},
                { label: "TIPO", name: 'type_cobranca', value: false, align: 'left', format: ''},
                { label: "ID SAFE2PAY", name: 'id_transaction', value: false, align: 'left', format: ''},
            ]);
            setUrl(`${backendURL}/empresas/relCompanys`);
        }
    }

    // eslint-disable-next-line
    useEffect(() => alterRel(), [origem]);

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="RELATORIO" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                <ListItem>
                    <SelectValueForLabel
                        label="FILTRO"
                        value={filtro}
                        setValue={setFiltro}
                        variant="outlined"
                        options={[
                            {value: '0', label: 'MENSAL'},
                            {value: '1', label: 'TODOS EM ATRASO'},
                        ]}
                    />
                </ListItem>

                {filtro === "0" && (
                    <ListItem>
                        <Input
                            label="MES"
                            type="month"
                            value={mes}
                            onChange={e => setMes(e.target.value)}
                        />
                    </ListItem>
                )}

                <ListItem>
                    <SelectValueForLabel
                        label="ORDEM"
                        value={order}
                        variant="outlined"
                        setValue={setOrder}
                        options={[
                            {value: 'id', label: 'VENCIMENTO'},
                            {value: 'fantasia', label: 'PAGAMENTO'},
                            {value: 'razao', label: 'RAZAO SOCIAL'},
                            {value: 'endereco', label: 'FANTASIA'},
                            {value: 'bairro', label: 'CODIGO ID'},
                            {value: 'cidade', label: 'CIDADE'},
                            {value: 'data_adessao', label: 'VALOR BOLETO'},
                        ]}
                    />
                </ListItem>

                {columns.length > 0 && (
                    <ListItem>
                        <div style={{display:'flex', flexWrap:'wrap'}}>
                            {columns.map((item, index) => (
                                <div style={{width:'50%'}}>
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox 
                                                id={`check${index}`}
                                                style={{padding: '1px'}}
                                                color="primary" 
                                                checked={item.value}
                                                onChange={(e) => handleChange(e, item.name)}
                                            />
                                        }
                                        label={item.label}
                                    />
                                </div>
                            ))}
                        </div>
                    </ListItem>
                )}

                <ListItem style={{flexDirection:'column', alignItems:'flex-start'}}>
                    <span>ORIENTAÇÃO DA PAGINA</span>
                    <RadioGroup row value={landscape} onChange={e => setLandscape(e.target.value)}>
                        <FormControlLabel value="P" control={<Radio color='primary' />} label="RETRATO" />
                        <FormControlLabel value="L" control={<Radio color='primary' />} label="PAISAGEM" />
                    </RadioGroup>
                </ListItem>

                <ListItem>
                    <Button 
                        onClick={() => viewRel()}
                        fullWidth 
                        variant="contained" 
                        color="secondary"
                    >
                        RELATORIO
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
