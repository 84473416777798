import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Template from '../../components/Template/Template';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import TableData from '../../components/Table/TableData';
import DrawerInfoParcela from '../../components/Drawer/DrawerInfoParcela';
import DrawerBuscaContas from '../../components/Drawer/DrawerBuscaContas';
import DrawerRelCobrancas from '../../components/Drawer/DrawerRelCobrancas';
import { CardSmall } from '../../components/Card/Card';
import api, { apiEmissor } from '../../services/api';
import { startMes, endMes, dateBrl, dateUsd } from '../../services/date';
import { displayMoney, displayNumber3 } from '../../services/displayValue';

export default function Financeiro() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [openLoad, setOpenLoad] = useState(false);
    const [txtFiltro, setTxtFiltro] = useState(null);
    const [drawerBusca, setDrawerBusca] = useState(false);
    const [drawerInfo, setDrawerInfo] = useState(false);
    const [infoDrawer, setInfoDrawer] = useState(null);
    const [rows, setRows] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const [atrasados, setAtrasados] = useState({quant: 0, value: 0});
    const [pagos, setPagos] = useState({quant: 0, value: 0});
    const [recebidos, setRecebidos] = useState({quant: 0, value: 0});
    const [avencer, setAvencer] = useState({quant: 0, value: 0});
    const [vence_hj, setVenceHj] = useState({quant: 0, value: 0});
    const [processamento, setProcessamento] = useState({quant: 0, value: 0});
    const [baixados, setBaixados] = useState({quant: 0, value: 0});
    const [totalMes, setTotalMes] = useState({quant: 0, value: 0});
    const [origem, setOrigem] = useState(2);
    const [openDrawerRel, setOpenDrawerRel] = useState(false);
    const [columns] = useState([
        { id: 'id', label: 'COD', minWidth: 20, format: displayNumber3 },
        { id: 'valor_boleto', label: 'VALOR', minWidth: 50, format: displayMoney, align: 'right' },
        { id: 'vencimento', label: 'VENCIMENTO', minWidth: 50, format: dateBrl },
        { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
        { id: 'cidade', label: 'CIDADE', minWidth: 100 },
        { id: 'razao', label: 'RAZAO', minWidth: 200 },
    ]);

    async function loadSite() {
        setOpenLoad(true);
        const response = await apiEmissor.get(`/pagamentos/listPagamentosByVencimento/${startMes()}/${endMes()}`);
        if(response && response.status === 200 && response.data){
            let registers = response.data;
            if(registers.length > 0){
                if(Number(infoLogin.revenda) > 0){
                    registers = registers.filter(i => Number(i.revenda) === Number(infoLogin.cod_site));
                }
                const formatRegisters = registers.map(item => ({
                    ...item, valor_boleto: item.valor_sistema, liquido: item.valor_liquido,
                    status_parcela: item.status_pagamento, recebido: 0
                }));
                setAllRows(formatRegisters);
                if(txtFiltro) setTxtFiltro(null);
            }else{
                setAllRows([]);
            }
        }
        setOpenLoad(false);
    }

    const loadData = async() => {
        setOpenLoad(true);
        const url = `/financeiro/loadContas/${infoLogin.id}/${jwt}/${startMes()}/${endMes()}`;
        const response = await api.get(url);
        console.log(response);
        if(response.status === 200 && response.data) setAllRows(response.data);
        setOpenLoad(false);
        if(txtFiltro) setTxtFiltro(null);
    }

    const handleDrawer = (info) => {
        setInfoDrawer(info);
        setDrawerInfo(true);
    }

    function filterParcela(infoParcela, status) {
        const { status_parcela, vencimento, recebido } = infoParcela;
        if(status === 1){
            if(Number(status_parcela) === 1 && moment(dateUsd()).isAfter(vencimento)){
                return infoParcela;
            }
        }else if(status === 2){
            if(Number(status_parcela) === 3){
                return infoParcela;
            }
        }else if(status === 3){
            if(Number(recebido) === 1){
                return infoParcela;
            }
        }else if(status === 4){
            if(Number(status_parcela) === 1 && moment(dateUsd()).isBefore(vencimento)){
                return infoParcela;
            }
        }else if(status === 5){
            if(Number(status_parcela) === 1 && dateUsd() === vencimento){
                return infoParcela;
            }
        }else if(status === 6){
            if(Number(status_parcela) === 2){
                return infoParcela;
            }
        }else if(status === 7){
            if((Number(status_parcela) === 7 || Number(status_parcela) === 12) && Number(recebido) === 0){
                return infoParcela;
            }
        }
    }

    const isColor = (row) => {
        let color = '#000';
        if(filterParcela(row, 1)) color = "#a71a1a";
        else if(filterParcela(row, 2)) color = "#6bbd5b";
        else if(filterParcela(row, 3)) color = "#248fb2";
        else if(filterParcela(row, 5)) color = "#4d524e";
        else if(filterParcela(row, 6)) color = "#004805";
        else if(filterParcela(row, 7)) color = "#3c0263";
        return color;
    }

    const filter = (cod) => {
        if(cod === 'Atrasadas') setRows(allRows.filter(r => filterParcela(r, 1)));
        else if(cod === 'Pagas') setRows(allRows.filter(r => filterParcela(r, 2)));
        else if(cod === 'Em Mãos') setRows(allRows.filter(r => filterParcela(r, 3)));
        else if(cod === 'A vencer') setRows(allRows.filter(r => filterParcela(r, 4)));
        else if(cod === 'Vence Hoje') setRows(allRows.filter(r => filterParcela(r, 5)));
        else if(cod === 'Em Processamento') setRows(allRows.filter(r => filterParcela(r, 6)));
        else if(cod === 'Baixados') setRows(allRows.filter(r => filterParcela(r, 7)));
        setTxtFiltro(cod);
    }

    function handleOrigem(cod){
        setOrigem(cod);
    }

    function reset() {
        if(origem === 1) loadSite();
        else if(origem === 2) loadData();
    }

    useEffect(() => {
        if(allRows){
            let isAtrasada = {quant: 0, value: 0};
            let isPaga = {quant: 0, value: 0};
            let isRecebida = {quant: 0, value: 0};
            let isAvencer = {quant: 0, value: 0};
            let isVenceHj = {quant: 0, value: 0};
            let isProcessamento = {quant: 0, value: 0};
            let isBaixada = {quant: 0, value: 0};
            let isTotal = {quant: 0, value: 0};

            allRows.forEach(element => {
                if(filterParcela(element, 1)){
                    isAtrasada.quant += 1;
                    isAtrasada.value += Number(element.valor_boleto);
                }else if(filterParcela(element, 2)){
                    isPaga.quant += 1;
                    isPaga.value += Number(element.liquido);
                }else if(filterParcela(element, 3)){
                    isRecebida.quant += 1;
                    isRecebida.value += Number(element.liquido);
                }else if(filterParcela(element, 4)){
                    isAvencer.quant += 1;
                    isAvencer.value += Number(element.valor_boleto)
                }else if(filterParcela(element, 5)){
                    isVenceHj.quant += 1;
                    isVenceHj.value += Number(element.valor_boleto);
                }else if(filterParcela(element, 6)){
                    isProcessamento.quant += 1;
                    isProcessamento.value += Number(element.valor_boleto);
                }else if(filterParcela(element, 7)){
                    isBaixada.quant += 1;
                    isBaixada.value += Number(element.valor_boleto);
                }
                isTotal.quant += 1;
                isTotal.value += Number(element.valor_boleto);
            });

            setAtrasados(isAtrasada);
            setPagos(isPaga);
            setRecebidos(isRecebida);
            setAvencer(isAvencer);
            setVenceHj(isVenceHj);
            setProcessamento(isProcessamento);
            setBaixados(isBaixada);
            setTotalMes(isTotal);
            setRows(allRows);
        }
    }, [allRows]);

    useEffect(() => {
        if(origem === 1) loadSite();
        else if(origem === 2) loadData();
        // eslint-disable-next-line
    }, [origem]);

    return(
        <Template menuActive="financeiro">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setOpenDrawerRel(true)}
                    >
                        RELATORIOS
                    </Button> */}
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={4} container justify="flex-end" alignItems="center">
                    {txtFiltro && (
                        <Chip
                            icon={<FaSearch />}
                            label={txtFiltro}
                            onDelete={() => reset()}
                            variant="outlined"
                            style={{ marginRight: 10 }}
                        />
                    )}
                    <Fab 
                        color="primary" 
                        aria-label="add" 
                        size="medium"
                        onClick={() => setDrawerBusca(true)}
                    >
                        <FaSearch />
                    </Fab>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#a71a1a" onClick={() => filter('Atrasadas')}>
                        <h1>{atrasados.quant}</h1>
                        <div>
                            <span>ATRASADOS</span>
                            <br />
                            <span>R$ {displayMoney(atrasados.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#6bbd5b" onClick={() => filter('Pagas')}>
                        <h1>{pagos.quant}</h1>
                        <div>
                            <span>PAGOS</span>
                            <br />
                            <span>R$ {displayMoney(pagos.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#248fb2" onClick={() => filter('Em Mãos')}>
                        <h1>{recebidos.quant}</h1>
                        <div>
                            <span>EM MÃOS</span>
                            <br />
                            <span>R$ {displayMoney(recebidos.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#091114" onClick={() => filter('A vencer')}>
                        <h1>{avencer.quant}</h1>
                        <div>
                            <span>A VENCER</span>
                            <br />
                            <span>R$ {displayMoney(avencer.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <CardSmall color="#4d524e" onClick={() => filter('Vence Hoje')}>
                        <h1>{vence_hj.quant}</h1>
                        <div>
                            <span>VENCE HOJE</span>
                            <br />
                            <span>R$ {displayMoney(vence_hj.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#004805" onClick={() => filter('Em Processamento')}>
                        <h1>{processamento.quant}</h1>
                        <div>
                            <span>EM PROCESSO</span>
                            <br />
                            <span>R$ {displayMoney(processamento.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#3c0263" onClick={() => filter('Baixados')}>
                        <h1>{baixados.quant}</h1>
                        <div>
                            <span>BAIXADOS</span>
                            <br />
                            <span>R$ {displayMoney(baixados.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSmall color="#091114" onClick={() => {}}>
                        <h1>{totalMes.quant}</h1>
                        <div>
                            <span>TOTAL</span>
                            <br />
                            <span>R$ {displayMoney(totalMes.value)}</span>
                        </div>
                    </CardSmall>
                </Grid>
                
                <Grid item xs={12} sm={4}>
                    <h3 style={{fontSize:'20pt'}}>PARCELAS</h3>
                </Grid>
                <Grid item xs={12} sm={5}></Grid>
                <Grid item xs={12} sm={3}>
                    <ButtonGroup color="primary" fullWidth aria-label="outlined primary button group" >
                        <Button
                            variant={origem === 1 ? "contained" : "outlined"}
                            onClick={() => handleOrigem(1)}
                        >
                            SITE
                        </Button>
                        <Button
                            variant={origem === 2 ? "contained" : "outlined"}
                            onClick={() => handleOrigem(2)}
                        >
                            ERP
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <TableData
                            columns={columns}
                            rows={rows}
                            clickFunction={handleDrawer}
                            isColor={isColor}
                            vh="80vh"
                        />
                    </Paper>
                </Grid>
            </Grid>
            <SimpleBackdrop open={openLoad} />
            <DrawerInfoParcela 
                info={infoDrawer}
                setInfo={setInfoDrawer}
                state={drawerInfo}
                setState={setDrawerInfo}
                load={setOpenLoad}
                allRows={allRows}
                setAllRows={setAllRows}
                loadData={loadData}
            />
            <DrawerBuscaContas
                state={drawerBusca}
                setState={setDrawerBusca}
                setRows={setAllRows}
                setTxt={setTxtFiltro}
                openLoad={setOpenLoad}
                origem={origem}
            />
            <DrawerRelCobrancas
                state={openDrawerRel}
                setState={setOpenDrawerRel}
                origem={origem}
            />
        </Template>
    );
}