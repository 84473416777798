import React, { useState, Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SelectValueForLabel from '../../components/Selects/SelectValueForLabel';
import Input from '../../components/Inputs/Input';
import Snack from '../../components/Alert/Snack';
import { backendURL } from '../../services/api';
import { startMes, endMes, isValidDate } from '../../services/date';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        marginRight: 5,
        marginLeft: -5,
    }
});

export default function DrawerRelRevenda({ state, setState, origem }) {
    const classes = useStyles();
    const [filtro, setFiltro] = useState("0");
    const [mesBusca, setMesBusca] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [msgSnack, setMsgSnack] = useState('');
    const [columns, setColumns] = useState([
        { label: "RAZAO", name: 'razao', value: true, cod: 0},
        { label: "FANTASIA", name: 'fantasia', value: true, cod: 1},
        { label: "CIDADE", name: 'cidade', value: true, cod: 2},
        { label: "ADESSÃO", name: 'data_adessao', value: true, cod: 3},
        { label: "STATUS", name: 'situacao', value: true, cod: 4},

        { label: "CODIGO", name: 'id', value: false, cod: 5},
        { label: "RESPONSAVEL", name: 'responsavel', value: false, cod: 6},
        { label: "CNPJ", name: 'cnpj', value: false, cod: 7},
        { label: "TELEFONE", name: 'telefone', value: false, cod: 8},
        { label: "DIA PAG.", name: 'dia_pagamento', value: false, cod: 9},
        { label: "VALOR", name: 'valor_suporte', value: false, cod: 10},
    ]);
    const [columns2, setColumns2] = useState([
        { label: "RAZAO", name: 'razao', value: true, cod: 0},
        { label: "FANTASIA", name: 'fantasia', value: true, cod: 1},
        { label: "CIDADE", name: 'cidade', value: true, cod: 2},
        { label: "ATIVAÇÃO", name: 'data_ativacao', value: true, cod: 3},
        { label: "EXPIRAÇÃO", name: 'data_expiracao', value: true, cod: 4},
        
        { label: "CODIGO", name: 'id', value: false, cod: 5},
        { label: "RESPONSAVEL", name: 'responsavel', value: false, cod: 6},
        { label: "CNPJ", name: 'cnpj', value: false, cod: 7},
        { label: "TELEFONE", name: 'telefone', value: false, cod: 8},
        { label: "ADESSÃO", name: 'create_data', value: false, cod: 9},
        { label: "VALOR", name: 'valor_sistema', value: false, cod: 10},
        { label: "STATUS", name: 'acesso', value: false, cod: 11},
    ]);

    function handleChange(array, setArray, cod) {
        const alter = array.map(i => {
            if(Number(i.cod) === Number(cod)) return {...i, value: !i.value};
            return i;
        });
        setArray(alter);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function viewRel() {
        if(filtro === '0'){
            if(isValidDate(startMes(mesBusca))){
                const urlRel = `${backendURL}/revenda/relRevenda/${startMes(mesBusca)}/${endMes(mesBusca)}/${origem}`;
                if(window && window.ReactNativeWebView){
                    const dataPost = JSON.stringify({pdf: urlRel})
                    window.ReactNativeWebView.postMessage(dataPost);
                }else{
                    window.open(urlRel);
                }
            }else{
                setMsgSnack('Selecione um periodo valido');
                setOpenSnack(true);
            }
        }else if(filtro === '1') {
            const erp = columns.filter(i => i.value).map(i => i.cod).toString();
            const site = columns2.filter(i => i.value).map(i => i.cod).toString();
            const url = `${backendURL}/revenda/relRevendaAll/${origem}/${erp}/${site}`;
            if(window && window.ReactNativeWebView){
                const dataPost = JSON.stringify({pdf: url})
                window.ReactNativeWebView.postMessage(dataPost);
            }else{
                window.open(url);
            }
        }
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="RELATORIO" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                <ListItem>
                    <SelectValueForLabel
                        label="FILTRO"
                        value={filtro}
                        setValue={setFiltro}
                        variant="outlined"
                        options={[
                            {value: '0', label: 'RELATORIO MENSAL'},
                            {value: '1', label: 'TODAS AS EMPRESAS'},
                        ]}
                    />
                </ListItem>

                {filtro === '0' && (
                    <ListItem>
                        <Input
                            size="small"
                            label="MÊS DA BUSCA"
                            type="month"
                            value={mesBusca}
                            onChange={e => setMesBusca(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                )}

                {filtro === "1" && (
                    <>
                        <ListItem>ERP</ListItem>
                        <ListItem>
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                {columns.map((item, index) => (
                                    <div style={{width:'50%'}}>
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox 
                                                    id={`check${index}`}
                                                    style={{padding: '1px'}}
                                                    color="primary" 
                                                    checked={item.value}
                                                    onChange={() => handleChange(columns, setColumns, item.cod)}
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </div>
                                ))}
                            </div>
                        </ListItem>

                        <ListItem>SITE</ListItem>
                        <ListItem>
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                {columns2.map((item, index) => (
                                    <div style={{width:'50%'}}>
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox 
                                                    id={`check${index}`}
                                                    style={{padding: '1px'}}
                                                    color="primary" 
                                                    checked={item.value}
                                                    onChange={() => handleChange(columns2, setColumns2, item.cod)}
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </div>
                                ))}
                            </div>
                        </ListItem>
                    </>
                )}

                <ListItem>
                    <Button 
                        onClick={() => viewRel()}
                        fullWidth 
                        variant="contained" 
                        color="secondary"
                    >
                        RELATORIO
                    </Button>
                </ListItem>
            </List>
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                message={msgSnack}
                time={3000}
            />
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
