export const removeMask = (mask) => {
    let regra = /[^a-z0-9]/gi;
    const value = mask.replace(regra, "");
    return value;
}

export const converterMoeda = (value) => {
    if(value === ''){
		return 0;
	}else{
        var val = String(value);
		var real = val.replace(".","");
        var real2 = real.replace(".","");
		var cents = real2.replace(",",".");
        return Number(cents);
	}
}

export const displayMoney = (value) => {
    if(value){
        const format = Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
        }).format(value);
        return format;
    }else{
        return '0,00';
    }
}

export const displayNumber3 = (number) => ("000" + number).slice(-3);

export const typeSafe2Pay = (cod) => {
    if(Number(cod) === 1) return 'BOLETO';
    else if(Number(cod) === 2) return 'C. CREDITO';
    else if(Number(cod) === 6) return 'PIX';
    else if(Number(cod) === 9) return 'ASS CARTAO';
    else if(Number(cod) === 0) return 'DINHEIRO';
    else if(Number(cod) === 14) return 'PIX ESTATICO';
}

export const labelMes = (cod) => {
    if(Number(cod) === 1) return `${cod} MÊS`;
    else if(Number(cod) > 1) return `${cod} MESES`;
    else return ``;
}