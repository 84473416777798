import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: red[700],
        color: '#fff',
    },
});

export default function ModalOptions({ open, setOpen, list, title }) {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <List>
                {list.map((item) => {
                    const { clickFunction = () => { }, Icon = <HelpOutlineIcon />, text = '', data = null } = item;
                    return (
                        <ListItem button onClick={() => clickFunction(data)} key={text}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Icon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={text} />
                        </ListItem>
                    );
                })}
            </List>
        </Dialog>
    );
}
