export const permissoes = [
    { value: '1', rota: '/revenda' },
    { value: '1', rota: '/cadastrorevenda' },
    { value: '0', rota: '/cadastrousuario' },
    { value: '2', rota: '/empresas' },
    { value: '2', rota: '/cadastroempresa' },
    { value: '2', rota: '/cadastroempresasite' },
    { value: '5', rota: '/financeiro' },
    { value: '6', rota: '/caixa' },
    { value: '3', rota: '/xml' },
    { value: '3', rota: '/detalhesxml' },
    { value: '4', rota: '/contrachave' },
    { value: '7', rota: '/downloads' },
    { value: '8', rota: '/contadores' },
    { value: '8', rota: '/cadastrocontador' },
];

export const arrayUfLabel = [
    'AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR',
    'RJ','RN','RO','RR','RS','SC','SE','SP','TO',
];

export const arrayUfCode = [
    { value: '12', label: 'AC' },
    { value: '27', label: 'AL' },
    { value: '13', label: 'AM' },
    { value: '16', label: 'AP' },
    { value: '29', label: 'BA' },
    { value: '23', label: 'CE' },
    { value: '53', label: 'DF' },
    { value: '32', label: 'ES' },
    { value: '52', label: 'GO' },
    { value: '21', label: 'MA' },
    { value: '31', label: 'MG' },
    { value: '50', label: 'MS' },
    { value: '51', label: 'MT' },
    { value: '15', label: 'PA' },
    { value: '25', label: 'PB' },
    { value: '26', label: 'PE' },
    { value: '22', label: 'PI' },
    { value: '41', label: 'PR' },
    { value: '33', label: 'RJ' },
    { value: '24', label: 'RN' },
    { value: '11', label: 'RO' },
    { value: '14', label: 'RR' },
    { value: '43', label: 'RS' },
    { value: '42', label: 'SC' },
    { value: '28', label: 'SE' },
    { value: '35', label: 'SP' },
    { value: '17', label: 'TO' },
];