import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { FaTimes, FaCalendarDay, FaDollarSign, FaInfo, FaFingerprint, FaBuilding } from 'react-icons/fa';
import ModalWindow from '../Modal/ModalWindow';
import { AlertConfirm, AlertInfo } from '../../components/Alert/Alert';
import { displayMoney } from '../../services/displayValue';
import api, { apisafe2pay, safe2pay } from '../../services/api';
import { dateBrl, isValidDate } from '../../services/date';
import { Primary, Secondary, useStyles } from './style';

export default function DrawerInfoParcela({ info, setInfo, state, setState, load, allRows, setAllRows, loadData }) {
    const classes = useStyles();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [api_safe2pay] = useState(infoLogin.api_safe2pay);
    const [openModal, setOpenModal] = useState(false);
    const [vencimento, setVencimento] = useState('');
    const history = useHistory();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setInfo(null);
        setState(open);
    };

    function handleCompany(id_empresa = 0) {
        const location = {
            pathname: '/cadastroempresa',
            state: { id_empresa },
        }
        history.push(location);
    }

    function update(dataApi = null) {
        if(dataApi){
            const alter = allRows.map(item => {
                if(item.id_transaction === info.id_transaction){
                    return {...item, status_parcela: dataApi.Status, message: dataApi.Message, ...dataApi}
                }
                return item;
            });
            setAllRows(alter);
        }else{
            loadData();
        }
    }

    const consult = async() => {
        load(true);
        setState(false);
        const response = await apisafe2pay.get(`/transaction/Get?Id=${info.id_transaction}`, {
            headers: {
                safe2pay: api_safe2pay
            }
        });
        console.log(response);
        if(response.status === 200){
            if(response.data.HasError){
                AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
            }else{
                const { Status, Message, CheckingAccounts = null } = response.data.ResponseDetail;
                let dataApi = { Status, Message, id: info.id_transaction };
                if(CheckingAccounts){
                    dataApi['data_pagamento'] = CheckingAccounts[0].ReleaseDate;
                    dataApi['valor_pago'] = Number(CheckingAccounts[0].Amount) + Number(CheckingAccounts[0].Tax);
                    dataApi['taxa'] = CheckingAccounts[0].Tax;
                    dataApi['liquido'] = CheckingAccounts[0].Amount;
                }
                const resp = await api.post(`/financeiro/atualizarConta/${infoLogin.id}/${jwt}`, qs.stringify(dataApi));
                console.log(resp);
                update(dataApi);
                AlertInfo('success', `BOLETO ${Message}`, "");
            }
        }else{
            AlertInfo('error', "NÃO FOI POSSIVEL CONSULTAR", "");
        }
        load(false);
    }

    const alterBoleto = async() => {
        const isDateValid = isValidDate(vencimento);
        if(isDateValid){
            load(true);
            setOpenModal(false);
            setState(false);
            const data = {
                Id: info.id_transaction,
                PaymentMethod: 1,
                PaymentObject: {
                    Command: 1,
                    DueDate: vencimento
                }
            };
            const response = await safe2pay.post(`/Payment`, data, {
                headers: {
                    safe2pay: api_safe2pay
                }
            });
            if(response && response.status === 200 && response.data){
                if(!response.data.HasError){
                    const dataApi = qs.stringify({
                        id_transaction: data.Id,
                        vencimento: data.PaymentObject.DueDate
                    });
                    const resp = await api.post(`/financeiro/alterarVencimentoBoleto/${infoLogin.id}/${jwt}`, dataApi);
                    if(resp && resp.status === 200 && resp.data){
                        if(resp.data.success){
                            load(false);
                            setState(false);
                            AlertInfo('success', "VENCIMENTO DO BOLETO ATUALIZADO", '');
                            loadData();
                            return;
                        }
                    }
                    console.log(resp);
                }
            }
            console.log(response);
            AlertInfo('error', 'FALHA NA OPERAÇÃO', '');
        }
    }

    const cancel = () => {
        setState(false);
        AlertConfirm('', 'CANCELAR BOLETO?', '', 'SIM').then(async (res) => {
            if(res.isConfirmed){
                load(true);
                const response = await apisafe2pay.delete(`/BankSlip/WriteOffBankSlip?idTransaction=${info.id_transaction}`, {
                    headers: {
                        safe2pay: api_safe2pay
                    }
                });
                console.log(response);
                if(response.status === 200){
                    if(response.data.HasError){
                        AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
                        load(false);
                    }else{
                        if(response.data.ResponseDetail) {
                            const dataApi = {
                                id: info.id_transaction,
                                Status: 12,
                                Message: 'Em Cancelamento',
                                data_pagamento: moment().format('YYYY-MM-DD'),
                                valor_pago: info.valor_suporte,
                                liquido: info.valor_suporte,
                            };
                            const resp = await api.post(`/financeiro/atualizarConta/${infoLogin.id}/${jwt}`, qs.stringify(dataApi));
                            console.log(resp);
                            update();
                            AlertInfo('success', `BOLETO CANCELADO`, "");
                        }else{
                            AlertInfo('error', "NÃO FOI POSSIVEL CANCELAR O BOLETO", "");
                            load(false);
                        }
                    }
                }else{
                    AlertInfo('error', "NÃO FOI POSSIVEL CONSULTAR", "");
                    load(false);
                }
            }
        });
    }

    async function cancelCarnet() {
        setState(false);
        AlertConfirm('', 'CANCELAR TODO O CARNÊ?', ``, 'SIM').then(async (res) => {
            if(res.isConfirmed){
                load(true);
                const response = await apisafe2pay.delete(`/Carnet/Delete?identifier=${info.identifier_carnet}`, {
                    headers: {
                        safe2pay: api_safe2pay
                    }
                });
                console.log(response);
                if(response.status === 200){
                    if(response.data.HasError){
                        AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
                        load(false);
                    }else{
                        const dataApi = qs.stringify({ identifier_carnet: info.identifier_carnet });
                        const resp = await api.post(`/financeiro/deletCarnet/${infoLogin.id}/${jwt}`, dataApi);
                        console.log(resp);
                        update();
                        AlertInfo('success', `CANCELAMENTO SOLICITADO`, "");
                    }
                }else{
                    AlertInfo('error', "NÃO FOI POSSIVEL CANCELAR", "");
                    load(false);
                }
            }
        });
    }

    const recebido = async() => {
        setState(false);
        AlertConfirm('', 'MARCAR BOLETO COMO PAGO', '', 'SIM').then(async (res) => {
            if(res.isConfirmed){
                load(true);
                const response = await apisafe2pay.delete(`/BankSlip/WriteOffBankSlip?idTransaction=${info.id_transaction}`, {
                    headers: {
                        safe2pay: api_safe2pay
                    }
                });
                console.log(response);
                if(response.status === 200){
                    if(response.data.HasError){
                        AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
                        load(false);
                    }else{
                        if(response.data.ResponseDetail) {
                            const dataApi = {
                                id: info.id_transaction,
                                Status: 11,
                                Message: 'Liberado',
                                data_pagamento: moment().format('YYYY-MM-DD'),
                                valor_pago: info.valor_suporte,
                                liquido: info.valor_suporte,
                                recebido: 1,
                            };
                            const resp = await api.post(`/financeiro/atualizarConta/${infoLogin.id}/${jwt}`, qs.stringify(dataApi));
                            console.log(resp);
                            update();
                            AlertInfo('success', `BOLETO BAIXADO`, "");
                        }else{
                            AlertInfo('error', "NÃO FOI POSSIVEL BAIXAR O BOLETO", "");
                            load(false);
                        }
                    }
                }else{
                    AlertInfo('error', "NÃO FOI POSSIVEL CONSULTAR", "");
                    load(false);
                }
            }
        });
    }

    function isTypeTransaction(cod) {
        if(Number(cod) === 1) return 'BOLETO';
        else if(Number(cod) === 2) return 'CARTÃO CREDITO';
        else if(Number(cod) === 6) return 'PIX';
        else if(Number(cod) === 9) return 'ASSINATURA';
    }

    function openExternalLink(link) {
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: link})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(link);
        }
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Detalhes da Parcela" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                {info && (
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaFingerprint /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ info.id_transaction }</Primary>
                                <Secondary>{isTypeTransaction(info.type_transaction || info.type_cobranca)}</Secondary>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaBuilding /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{info.fantasia}</Primary>
                            </ListItemText>
                        </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}>
                                <FaCalendarDay />
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ dateBrl(info.create_transaction) }</Primary>
                                <Secondary>Emissão</Secondary>
                            </ListItemText>

                            <ListItemIcon className={classes.icon}>
                                <FaCalendarDay />
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ dateBrl(info.vencimento) }</Primary>
                                <Secondary>Vencimento</Secondary>
                            </ListItemText>
                        </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}>
                                <FaInfo />
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ info.message }</Primary>
                                <Secondary>Status</Secondary>
                            </ListItemText>
                            {info.data_pagamento && info.data_pagamento !== '0000-00-00' && (
                                <>
                                    <ListItemIcon className={classes.icon}>
                                        <FaCalendarDay />
                                    </ListItemIcon>
                                    <ListItemText disableTypography>
                                        <Primary>{ dateBrl(info.data_pagamento) }</Primary>
                                        <Secondary>Pagamento</Secondary>
                                    </ListItemText>
                                </>
                                    
                            )}
                        </ListItem>

                        {info.valor_pago && Number(info.valor_pago) > 0 && (
                            <>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon className={classes.icon}>
                                        <FaDollarSign />
                                    </ListItemIcon>
                                    <ListItemText disableTypography>
                                        <Primary>{ displayMoney(info.valor_pago) }</Primary>
                                        <Secondary>VALOR PAGO</Secondary>
                                    </ListItemText>
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemIcon className={classes.icon}>
                                        <FaDollarSign />
                                    </ListItemIcon>
                                    <ListItemText disableTypography>
                                        <Primary>{ displayMoney(info.taxa) }</Primary>
                                        <Secondary>TAXA</Secondary>
                                    </ListItemText>

                                    <ListItemIcon className={classes.icon}>
                                        <FaDollarSign />
                                    </ListItemIcon>
                                    <ListItemText disableTypography>
                                        <Primary>{ displayMoney(info.liquido) }</Primary>
                                        <Secondary>VALOR A RECEBER</Secondary>
                                    </ListItemText>
                                </ListItem>
                            </>
                        )}

                        {info.situacao &&(
                            <ListItem>
                                <Button 
                                    onClick={() => handleCompany(info.id_empresa)}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    DADOS EMPRESA
                                </Button>
                            </ListItem>
                        )}

                        <ListItem>
                            <Button 
                                onClick={() => openExternalLink(info.link_parcela)}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                            >
                                BOLETO
                            </Button>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={() => openExternalLink(info.link_carnet)}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                            >
                                CARNÊ
                            </Button>
                        </ListItem>

                        {Number(info.status_parcela) === 1 && (
                            <ListItem>
                                <Button 
                                    onClick={() => recebido()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    RECEBIDO
                                </Button>
                            </ListItem>
                        )}

                        {Number(info.status_parcela) === 1 && (
                            <ListItem>
                                <Button 
                                    onClick={() => setOpenModal(true)}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    ALTERAR BOLETO
                                </Button>
                            </ListItem>
                        )}

                        <ListItem>
                            <Button 
                                onClick={() => consult()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                            >
                                CONSULTAR
                            </Button>
                        </ListItem>
                        
                        {Number(info.status_parcela) === 1 && (
                            <ListItem>
                                <Button 
                                    onClick={() => cancel()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    CANCELAR BOLETO
                                </Button>
                            </ListItem>
                        )}

                        {Number(info.status_parcela) === 1 && (
                            <ListItem>
                                <Button 
                                    onClick={() => cancelCarnet()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    CANCELAR CARNÊ
                                </Button>
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <ModalWindow
                open={openModal}
                setOpen={setOpenModal}
            >
                <div style={{width: 300}}>
                    <h4>ALTERAR VENCIMENTO DO BOLETO</h4>
                    <br/>
                    <TextField 
                        fullWidth
                        type="date"
                        label="NOVO VENCIMENTO" 
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={vencimento}
                        onChange={(e) => setVencimento(e.target.value)}
                    />
                    <br/><br/>
                    <Button 
                        onClick={() => alterBoleto()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                    >
                        ALTERAR DATA
                    </Button>
                </div>
            </ModalWindow>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
