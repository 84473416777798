import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const rand = () => Math.round(Math.random() * 20) - 10;

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
    },
    close: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    body: {
        padding: 7
    }
}));

export default function ModalWindow({ open, setOpen, children }) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <div className={classes.close}>
                    <FaTimes
                        onClick={handleClose}
                        style={{cursor:'pointer'}}
                    />
                </div>
                <div className={classes.body}>
                    {children}
                </div>
            </div>
        </Modal>
    );
}
