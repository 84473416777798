import styled from 'styled-components';

const Card = styled.div`
    width: 100%;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #9d9797;
    border-top: 5px solid #a71a1a;
    padding: 2px 10px 10px 10px;
`;

export const CardSmall = styled.div`
    width: 100%;
    height: 60px;
    background-color: ${props => props.color};
    color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    & h1 {
        font-size: 45px;
        margin-left: 5px;
    }
    & div {
        text-align: right;
    }
    & div span {
        font-size: 18px;
        margin-right: 5px;
    }
`;

export default Card;