import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AddIcon from '@material-ui/icons/Add';
import Input from '../Inputs/Input';
import { AlertInfo } from '../Alert/Alert';
import api from '../../services/api';


const useStyles = makeStyles({
    avatar: {
        backgroundColor: '#a71a1a',
        color: '#fff',
    },
});

export default function ModalAssinatura({ open, setOpen, id_empresa, email, name, doc, endereco, numero, bairro, cep, complemento, codigomunicipio, openLoad }) {
    const [plans, setPlans] = useState([]);
    const [selectPlan, setSelectPlan] = useState(true);
    const [name_plan, setNamePlan] = useState('');
    const [chargeDay, setChargeDay] = useState('');
    const [amount, setAmount] = useState('');
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [api_safe2pay] = useState(infoLogin.api_safe2pay);
    const classes = useStyles();

    async function createAssinatura(value) {
        if(value.idPlan) {
            openLoad(true);
            setOpen(false);
            const data = {
                idPlan: value.idPlan,
                email, name, doc, 
                endereco, numero, bairro, cep, complemento,
                codigomunicipio, id_empresa, api_safe2pay
            };
            const response = await api.post(`/safe2pay/createAssinatura`, qs.stringify(data));
            if(response && response.status === 200 && response.data){
                if(response.data.success){
                    navigator.clipboard.writeText(response.data.data.chargeUrl);
                    openLoad(false);
                    AlertInfo('success', 'LINK DE ASSINATURA GERADO', 'Link Copiado para area de tranferencia');
                }else{
                    AlertInfo('error', 'FALHA AO CRIAR ASSINATURA');
                    console.log(response);
                }
            }
        }
    };

    async function loadData() {
        const response = await api.get(`/safe2pay/getPlans/${api_safe2pay}`);
        if(response && response.status === 200 && response.data.success){
            setPlans(response.data.data.objects);
        }
    }

    function handleClose() {
        setOpen(false);
    }

    async function createPlan() {
        openLoad(true);
        setOpen(false);
        let error = '';
        if(name_plan.length > 0){
            if(chargeDay > 0 && chargeDay < 29){
                if(amount > 0){
                    const data = { name_plan: name_plan.toUpperCase(), chargeDay, amount, api_safe2pay };
                    const response = await api.post(`/safe2pay/createPlan`, qs.stringify(data));
                    console.log(response);
                    if(response && response.status === 200 && response.data){
                        if(response.data.success){
                            setSelectPlan(true);
                            openLoad(false);
                            setPlans([]);
                            AlertInfo('success', 'PLANO CRIADO').then(() => {
                                loadData();
                                setOpen(true);
                            });
                            return;
                        }
                    }else{
                        error = 'FALHA DE CONEXAO';
                    }
                }else {
                    error = 'DIGITE UM VALOR VALIDO PARA COBRANÇA';
                }
            }else{
                error = 'SELECIONE 1 DIA ENTRE 1 E 28';
            }
        }else{
            error = 'DIGITE UM NOME VALIDO PARA O PLANO';
        }
        openLoad(false);
        AlertInfo('error', error, '').then(() => setOpen(true));
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">SELECIONE O PLANO</DialogTitle>
            {selectPlan && (
                <List>
                    {plans.map((plan) => (
                        <ListItem button onClick={() => createAssinatura(plan)} key={plan.idPlan}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <CreditCardIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={plan.name} />
                        </ListItem>
                    ))}

                    <ListItem autoFocus button onClick={() => setSelectPlan(false)}>
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="NOVO PLANO" />
                    </ListItem>
                </List>
            )}
            {!selectPlan && (
                <List>
                    <ListItem>
                        <Input
                            type="text"
                            label="NOME PLANO"
                            value={name_plan}
                            onChange={e => setNamePlan(e.target.value)}
                            autoFocus
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="number"
                            label="DIA DA COMBRANÇA"
                            value={chargeDay}
                            onChange={e => setChargeDay(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="number"
                            label="VALOR MENSAL"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                        />
                    </ListItem>

                    <ListItem>
                        <Button  
                            variant="contained" 
                            color="primary"
                            fullWidth
                            onClick={() => createPlan()}
                        > 
                            CRIAR
                        </Button>
                    </ListItem>
                    <ListItem>
                        <Button  
                            variant="contained" 
                            color="inherit"
                            fullWidth
                            onClick={() => setSelectPlan(true)}
                        > 
                            CANCELAR
                        </Button>
                    </ListItem>
                </List>
            )}
        </Dialog>
    );
}
