import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320
    },
    icon: {
        minWidth: 24, 
        color: '#222',
    }
});

export const Primary = styled.p`
    color: #000;
    font-weight: bold;
`;

export const Secondary = styled.p`
    color: #000;
`;