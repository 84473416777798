import React from 'react';
import { FaQuestion } from 'react-icons/fa6';
import './CardDestakIcon.css';

export default function CardDestakIcon({icon = null, sizeIcon = 34, colorIcon = '#aaa', title = '', subTitle = ''}) {
    const Icon = icon ? icon : FaQuestion;
    return(
        <div className='containerCardDestak'>
            <div className='initCard'>
                <div className='containerIcon' style={{backgroundColor: colorIcon}}>
                    <Icon size={sizeIcon} />
                </div>
                <p>{title}</p>
                <h3><small>{subTitle}</small></h3>
            </div>
        </div>
    );
}