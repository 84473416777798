import React, { useState, useEffect } from 'react';
import { FaPix, FaBan, FaKey, FaDollarSign, Fa0, FaCheck, FaGlobe, FaComputer, FaFileCircleCheck, FaXmark } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import md5 from 'md5';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import LanguageIcon from '@material-ui/icons/Language';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardDestakIcon from '../../components/Card/CardDestakIcon';
import TableData from '../../components/Table/TableData';
import { dateBrl, dateBrlHrs } from '../../services/date';
import api, { apiEmissor, logout } from '../../services/api';

import GraficoReceber from './GraficoReceber';

export default function DashboardRevenda() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [rowsErp, setRowsErp] = useState([]);
    const [rowsSite, setRowsSite] = useState([]);
    const [noPaymentCompany, setNoPayment] = useState([]);
    const [logs, setLogs] = useState([]);
    const [bloq, setBLoq] = useState(0);
    const [libert, setLibert] = useState(0);
    const [adesoes, setAdesoes] = useState(0);
    const [adesoesSite, setAdesoesSite] = useState(0);
    const [payments, setPayments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [openLoad, setOpenLoad] = useState(false);
    const [openLoadSite, setOpenLoadSite] = useState(false);
    const history = useHistory();
    const listLogs = [
        { Icon: FaPix, color: "#6bbd5b", key: 'LIBERAÇÃO AUTOMATICA VIA PIX', text: 'LIBERAÇÃO VIA PIX' },
        { Icon: FaBan, color: "#a71a1a", key: 'BLOQUEADO AUTOMATICAMENTE POR ATRASO', text: 'BLOQUEADO AUTOMATICAMENTE' },
        { Icon: FaBan, color: "#a71a1a", key: 'BLOQUEADO MANUALMENTE', text: 'BLOQUEADO MANUALMENTE' },
        { Icon: FaKey, color: "#248fb2", key: 'LIBERADO MANUALMENTE', text: 'LIBERADO MANUALMENTE' },
        { Icon: FaFileCircleCheck, color: "#6bbd5b", key: 'LIBERAÇÃO AUTOMATICA PAGAMENTO BOLETO', text: 'LIBERAÇÃO VIA BOLETO' },
        { Icon: FaXmark, color: "#1e2b30", key: 'EMPRESA DELETADA', text: 'EMPRESA DELETADA' },
    ];

    async function loadCompanysSite() {
        setOpenLoadSite(true);
        try {
            const resp = await apiEmissor.get(`/empresas/loadEmpresas/${md5(infoLogin.id)}`);
            console.log(resp);
            if (resp && resp.status === 200 && resp.data) {
                const site = resp.data;
                const lastAdesoes = site.filter(i => String(i.createdata).includes(String(moment().format('YYYY-MM'))) && Number(infoLogin.id) === Number(i.revenda));
                setAdesoesSite(lastAdesoes.length);
                setRowsSite(site.filter(i => Number(i.revenda) === Number(infoLogin.id)).reverse());
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoadSite(false);
    }

    async function loadDataApi() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/home/getInfoDashboard/${infoLogin.id}/${jwt}`);
            if (response && response.status === 200 && response.data) {
                if (response.data.invalidJwt) {
                    logout();
                } else if (response.data.success) {
                    if (response.data.logs) {
                        const dataLogs = response.data.logs;
                        const isBloq = dataLogs.filter(i => String(i.log).includes('BLOQUEADO'));
                        const isLibe = dataLogs.filter(i => String(i.log).includes('LIBERA'));
                        setLogs(dataLogs);
                        setBLoq(isBloq.length);
                        setLibert(isLibe.length);
                    }
                    if (response.data.lastCompanys) {
                        const dataCompanys = response.data.lastCompanys;
                        const addInMonth = dataCompanys.filter(i => String(i.data_adessao).includes(String(moment().format('YYYY-MM'))));
                        setRowsErp(dataCompanys);
                        setAdesoes(addInMonth.length);
                    }
                    if (response.data.lastPayment) setPayments(response.data.lastPayment);
                    if (response.data.noPayment) setNoPayment(response.data.noPayment);
                    if (response.data.expiredLicenses) setLicenses(response.data.expiredLicenses);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    async function loadData() {
        loadDataApi();
        loadCompanysSite();
    }

    function handleOpenCompany(id) {
        const pathname = '/cadastroempresa';
        const location = {
            pathname,
            state: { id_empresa: id },
        }
        history.push(location);
    }

    function LoadDataPaper() {
        return (
            <>
                <br />
                <LinearProgress />
                <h3 style={{ textAlign: 'center' }}>Carregando dados...</h3>
                <LinearProgress color="secondary" />
            </>
        );
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} container spacing={3} justify='center'>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaBan}
                        colorIcon='#a71a1a'
                        title='BLOQUEIOS'
                        subTitle={bloq}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaCheck}
                        colorIcon='#6bbd5b'
                        title='DESBLOQUEIOS'
                        subTitle={`${libert}/${bloq}`}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaComputer}
                        colorIcon='#1e2b30'
                        title='ADIÇÕES/ERP'
                        subTitle={adesoes}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaGlobe}
                        title='ADIÇÕES/SITE'
                        subTitle={adesoesSite}
                    />
                </Grid>
            </Grid>

            {licenses.length > 0 && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                    >
                        LICENÇAS PRESTES A VENCER
                    </Button>
                    <Paper elevation={3}>
                        <TableData
                            columns={[
                                { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
                                { id: 'data_inicio', label: 'INICIO', minWidth: 80, format: dateBrl },
                                { id: 'data_expire', label: 'FIM', minWidth: 80, format: dateBrl }
                            ]}
                            rows={licenses}
                            clickFunction={(row) => handleOpenCompany(row.id_empresa)}
                            isColor={() => "#a71a1a"}
                            vh="20vh"
                        />
                    </Paper>
                </Grid>
            )}

            <Grid item xs={12} sm={5}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                >
                    EVENTOS
                </Button>
                <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto' }}>
                    {openLoad && (
                        <LoadDataPaper />
                    )}
                    <List component="nav">
                        {logs.map(emp => {
                            const getItemList = listLogs.filter(i => i.key === emp.log);
                            const { Icon, color, text } = getItemList[0];

                            return (
                                <ListItem button onClick={() => handleOpenCompany(emp.id_empresa)}>
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: color }}>
                                            <Icon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<span>{emp.fantasia}</span>}
                                        secondary={<span>{text} <br /> {dateBrlHrs(emp.data_log)}</span>}
                                    />
                                </ListItem>
                            );
                        })}
                        {(logs.length === 0 && !openLoad) && (
                            <ListItem button>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Fa0 />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<span>NENHUM EVENTO NOS ULTIMOS 5 DIAS</span>}
                                    secondary={dateBrl()}
                                />
                            </ListItem>
                        )}
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                >
                    ULTIMAS PAGAMENTOS
                </Button>
                <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto' }}>
                    {openLoad && (
                        <LoadDataPaper />
                    )}
                    <List component="nav">
                        {payments.map(emp => {
                            const isRecebido = Number(emp.recebido) === 1 ? true : false;
                            const isPix = Number(emp.is_pay_pix) > 0 ? true : false;
                            let Icon = FaFileCircleCheck;
                            if (isRecebido) Icon = FaDollarSign;
                            else if (isPix) Icon = FaPix;
                            return (
                                <ListItem button onClick={() => handleOpenCompany(emp.id)}>
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: isRecebido ? '#248fb2' : '#6bbd5b' }}>
                                            <Icon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={emp.fantasia}
                                        secondary={`${dateBrl(emp.data_pagamento)} |##| ${dateBrl(emp.vencimento)}`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={false} sm={3}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                >
                    COBRANÇAS
                </Button>
                <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto', width: '100%' }}>
                    <GraficoReceber />
                </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                >
                    ULTIMAS ADIÇÕES ERP
                </Button>
                <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto' }}>
                    {openLoad && (
                        <LoadDataPaper />
                    )}
                    <List component="nav">
                        {rowsErp.slice(0, 5).map(emp => (
                            <ListItem button onClick={() => handleOpenCompany(emp.id)}>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: '#6bbd5b' }}>
                                        <LaptopMacIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={emp.fantasia} secondary={dateBrl(emp.data_adessao)} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                >
                    ULTIMAS ADIÇÕES SITE
                </Button>
                <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto' }}>
                    {openLoadSite && (
                        <LoadDataPaper />
                    )}
                    <List component="nav">
                        {rowsSite.slice(0, 5).map(emp => (
                            <ListItem button>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: '#6bbd5b' }}>
                                        <LanguageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={emp.fantasia} secondary={dateBrl(emp.createdata)} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                >
                    EMPRESAS SEM FATURA
                </Button>
                <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto' }}>
                    {openLoad && (
                        <LoadDataPaper />
                    )}
                    <List component="nav">
                        {noPaymentCompany.map(emp => (
                            <ListItem button onClick={() => handleOpenCompany(emp.id)}>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: '#a71a1a' }}>
                                        <AttachMoneyIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={emp.fantasia} secondary={dateBrl(emp.data_adessao)} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>


        </Grid>
    );
}