import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import './style.css';

export default function Input({ next = null, ...rest}) {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    border: `2px solid #000`,
                },
                "& input": {
                    fontWeight: 'bold',
                },
                "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                    color: '#000',
                    fontWeight: 'bold'
                }
            }
        }
    }));

    function nextInput(event) {
        if(next){
            if(event.key === 'Enter'){
                var element = document.querySelector(`#${next}`);
                if(element) element.focus();
            }
        }
    }

    const classes = useStyles();
    return(
        <TextField
            fullWidth
            variant="outlined"
            onKeyPress={nextInput}
            classes={{
                root: classes.root,
            }}
            { ...rest }
        />
    );
}