import { useState, useRef, useEffect } from 'react';
import { FaLock } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '../../components/Inputs/Input';
import InputPassword from '../../components/Inputs/InputPassword';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alert/Alert';
import api from '../../services/api';
import { ContainerPage, stylePaper, AvatarIcon, ContainerIcon } from './styled';

export default function Login() {
    const [user, setUser] = useState('');
    const inputPass = useRef(null);
    const [openLoad, setOpenLoad] = useState(false);
    const history = useHistory();

    async function handleLogin(u = null, p = null) {
        const pass = inputPass.current.value;
        setOpenLoad(true);
        const data = qs.stringify({ 
            user: u ? u : user, 
            pass: p ? p : pass
        });
        console.log(data);
        const response = await api.post(`/login`, data);
        if(response.status === 200){
            if(response.data.infoLogin && response.data.jwt){
                sessionStorage.setItem('jwtRenove', response.data.jwt);
                sessionStorage.setItem('infoLogin', JSON.stringify(response.data.infoLogin));
                history.push('/');
                return;
            }
        }
        setOpenLoad(false);
        AlertInfo('error', 'DADOS INCORRETOS', '');
    }

    useEffect(() => {
        const parms = window.location.search;
        const parmsSearch = new URLSearchParams(parms);
        const arrParms = parmsSearch.toString().split("=");
        if(arrParms.length === 2){
            const [u, p] = arrParms;
            handleLogin(u, p);
        }
        // eslint-disable-next-line
    }, [])

    return(
        <ContainerPage>
            <Paper elevation={3} style={stylePaper}>
                <ContainerIcon>
                    <AvatarIcon>
                        <FaLock />
                    </AvatarIcon>
                    <Typography component="h1">
                        INSIRA OS DADOS PARA LOGIN
                    </Typography>
                </ContainerIcon>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            autoFocus
                            label="USUARIO"
                            value={user}
                            onChange={e => setUser(e.target.value)}
                            next="senha"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputPassword
                            label="SENHA"
                            id="senha"
                            inputRef={inputPass}
                            next="entrar"
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} />
                    <Grid item xs={6} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleLogin()}
                            id="entrar"
                        >
                            ENTRAR
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <SimpleBackdrop open={openLoad} />
        </ContainerPage>
    );
}