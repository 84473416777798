import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SelectValueForLabel from '../Selects/SelectValueForLabel';
import { AlertInfo } from '../Alert/Alert';
import api, { safe2pay, urlCalbackSafe2Pay } from '../../services/api';
import { removeMask } from '../../services/displayValue';
import { displayDataAmericToday, dateBrl, addMountfromDate, montData } from '../../services/date';
import { maskValidadeCartao, maskNumberCartao } from '../../services/mask';
import './style.css';

export default function ModalPagamento({ open, setOpen, info, load = null }) {
    const [pagamento, setPagamento] = useState('0');
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [api_safe2pay] = useState(infoLogin.api_safe2pay);
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [openLoad, setOpenLoad] = useState(false);
    const [valor_suporte, setValorSuporte] = useState(0);

    const [vencimento, setVencimento] = useState(displayDataAmericToday() || '');
    const [instrucoes, setInstrucoes] = useState('');
    const [mensagem, setMensagem] = useState('');

    const [nome_cartao, setNomeCartao] = useState('');
    const [numero_cartao, setNumeroCartao] = useState('');
    const [expiracao, setExpiracao] = useState('');
    const [cvc, setCvc] = useState('');
    const [parcelas, setParcelas] = useState('');

    const [linkQrCodePix, setLinkQrCodePix] = useState(null);
    const [linkPixCopiaCola, setPixCopiaCola] = useState(null);

    useEffect(() => {
        if(info && info.dia_pagamento) setVencimento(montData(info.dia_pagamento));
        if(info && info.valor_suporte) setValorSuporte(info.valor_suporte);
    }, [info]);

    const handleClose = () => {
        setOpen(false);
    };

    const calcParcelas = () => {
        let resp = [];
        for (let index = 0; index < parcelas; index++) {
            const element = {
                "Amount": valor_suporte,
                "DueDate": addMountfromDate(vencimento, index),
                "Message": ["Fique atento ao vencimento para evitar multas e juros", mensagem]
            };
            resp.push(element);
        }
        return resp;
    }

    const paymentObject = () => {
        let payment = {};
        if(pagamento === "0"){
            payment = {
                "Message": "SISTEMA RENOVE",
                "PenaltyAmount": 5.00,
                "InterestAmount": 0.033,
                "BankSlips": calcParcelas()
            }
        }else if(pagamento === "1"){
            payment = {
                "DueDate": dateBrl(vencimento),
                "Instruction": instrucoes,
                "Message": [info.descricao, mensagem],
                "CancelAfterDue": true,
                "IsEnablePartialPayment": false
            }
        }else if(pagamento === "2"){
            payment = {
                "Holder": nome_cartao.toUpperCase(),
                "CardNumber": numero_cartao,
                "ExpirationDate": expiracao,
                "SecurityCode": cvc,
                "InstallmentQuantity": parcelas,
                "SoftDescriptor": "RENOVE"
            }
        }

        return payment;
    }

    async function pay() {
        console.log(info);
        const dadosCliente = {
            "Name": (info['razao'].length > 0) ? info.razao : info.responsavel,
            "Identity": (info['cnpj'].length > 0) ? removeMask(info.cnpj) : removeMask(info.cpf),
            "Phone": removeMask(info.telefone),
            "Email": info.email,
            "Address": {
                "ZipCode": removeMask(info.cep),
                "Street": info.endereco,
                "Number": info.numero,
                "Complement": info.complemento,
                "District": info.bairro,
                "CityName": info.cidade,
                "StateInitials": info.estado,
                "CountryName": "Brasil"
            }
        };
        const products = [{
            "Code": "001",
            "Description": "MENSALIDADE DO SISTEMA",
            "UnitPrice": valor_suporte,
            "Quantity": 1
        }];
        const data = {
            "IsSandbox": false,
            "Application": "CONTROLE SISTEMA RENOVE",
            "Vendor": infoLogin.usuario,
            "CallbackUrl": urlCalbackSafe2Pay,
            "PaymentMethod": (pagamento === "0") ? "1" : pagamento,
            "Customer": dadosCliente,
            "Products": products,
            "PaymentObject": paymentObject(),
        }

        setOpenLoad(true);
        const url = (pagamento === "0") ? "/carnet" : "/Payment";
        const response = await safe2pay.post(url, data, {
            headers: {
                safe2pay: api_safe2pay
            }
        });
        console.log(response);
        if(response.status === 200){
            if(response.data.HasError){
                AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
            }else{
                if(pagamento === "0"){
                    const { CarnetUrl, BankSlips, Identifier } = response.data.ResponseDetail;
                    const db = { CarnetUrl, BankSlips: JSON.stringify(BankSlips), Identifier, valor_boleto: valor_suporte };
                    const resp = await api.post(`/financeiro/addCarnet/${infoLogin.id}/${jwt}/${info.id}`, qs.stringify(db));
                    console.log(resp);
                    setOpen(false);
                    AlertInfo('success', "CARNÊ GERADO", '').then(() => {
                        if(pagamento === "0"){
                            if(window && window.ReactNativeWebView){
                                const dataPost = JSON.stringify({pdf: CarnetUrl})
                                window.ReactNativeWebView.postMessage(dataPost);
                            }else{
                                window.open(CarnetUrl);
                            }
                        }
                    });
                }else{
                    const id_transaction = response.data.ResponseDetail.IdTransaction;
                    const id_empresa = info.id;
                    const status_parcela = response.data.ResponseDetail.Status;
                    const message = response.data.ResponseDetail.Message;
                    const bankslipnumber = response.data.ResponseDetail.BankSlipNumber || '';
                    const linha_digitavel = response.data.ResponseDetail.DigitableLine || '';
                    const identifier_carnet = '';
                    const valor_boleto = valor_suporte;
                    const type_cobranca = pagamento;
                    let link_parcela = ''
                    let link_carnet = '';

                    if(pagamento === "1"){
                        link_parcela = response.data.ResponseDetail.BankSlipUrl;
                    }else if(pagamento === "6"){
                        link_parcela = response.data.ResponseDetail.QrCode;
                        link_carnet = response.data.ResponseDetail.Key;
                        setLinkQrCodePix(link_parcela);
                        setPixCopiaCola(link_carnet);
                    }
                    const infoDb = { 
                        id_transaction, id_empresa, status_parcela, message, bankslipnumber, vencimento,
                        linha_digitavel, link_parcela, link_carnet, identifier_carnet, valor_boleto, type_cobranca
                    };
                    const resp = await api.post(`/financeiro/setCobranca/${infoLogin.id}/${jwt}`, qs.stringify(infoDb));
                    console.log(resp);
                    if(resp.status === 200){
                        if(pagamento !== "6"){
                            AlertInfo('success', message, '').then(() => {
                                if(pagamento === "1"){
                                    const linkBoleto = response.data.ResponseDetail.BankSlipUrl;
                                    if(window && window.ReactNativeWebView){
                                        const dataPost = JSON.stringify({pdf: linkBoleto})
                                        window.ReactNativeWebView.postMessage(dataPost);
                                    }else{
                                        window.open(linkBoleto);
                                    }
                                }
                            });
                        }
                    }
                    setOpen(false);
                }
                if(load) load();
            }
        }
        setOpenLoad(false);
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth >
            {openLoad && (
                <div className="loading">
                    <CircularProgress size={100} />
                    <br />
                    <Typography variant="h6" className="info">
                        PROCESSANDO PAGAMENTO...
                    </Typography>
                    <Typography variant="h6" className="info">
                        AGUARDE...
                    </Typography>
                </div>
            )}
            {!openLoad && !linkPixCopiaCola && (
                <>
                    <DialogTitle id="form-dialog-title">EXECUTAR PAGAMENTO</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <SelectValueForLabel 
                                    label="FORMA DE PAGAMENTO"
                                    value={pagamento}
                                    setValue={setPagamento}
                                    options={[
                                        { value: "0", label: 'CARNÊ' },
                                        { value: "1", label: 'BOLETO' },
                                        { value: "2", label: 'CARTÃO DE CRÉDITO' },
                                        { value: "6", label: 'PIX' },
                                    ]}
                                    variant="outlined" 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="VALOR SUPORTE"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={valor_suporte}
                                    onChange={e => setValorSuporte(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {pagamento === "0" && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="dense"
                                                label="PARCELAS"
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                value={parcelas}
                                                onChange={e => setParcelas(e.target.value)}
                                            />
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    margin="dense"
                                                    label="1° VENCIMENTO"
                                                    type="date"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={vencimento}
                                                    onChange={e => setVencimento(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    margin="dense"
                                                    label="MENSAGEM"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={mensagem}
                                                    onChange={e => setMensagem(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {pagamento === "1" && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="dense"
                                                label="VENCIMENTO"
                                                type="date"
                                                fullWidth
                                                variant="outlined"
                                                value={vencimento}
                                                onChange={e => setVencimento(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="dense"
                                                label="INSTRUÇÕES"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={instrucoes}
                                                onChange={e => setInstrucoes(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="dense"
                                                label="MENSAGEM"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={mensagem}
                                                onChange={e => setMensagem(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {pagamento === "2" && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="dense"
                                                label="NOME NO CARTÃO"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={nome_cartao}
                                                onChange={e => setNomeCartao(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="dense"
                                                label="NUMERO CARTÃO"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={numero_cartao}
                                                onChange={e => maskNumberCartao(e, setNumeroCartao)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                margin="dense"
                                                label="DATA EXPIÇÃO"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={expiracao}
                                                onChange={e => maskValidadeCartao(e, setExpiracao)}
                                                placeholder="MM/AAAA"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                type="number"
                                                margin="dense"
                                                label="CODIGO SEGURANÇA"
                                                fullWidth
                                                variant="outlined"
                                                value={cvc}
                                                onChange={e => setCvc(e.target.value)}
                                            />
                                        </Grid>
                            
                                        {pagamento === "2" && (
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    margin="dense"
                                                    label="PARCELAS"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={parcelas}
                                                    onChange={e => setParcelas(e.target.value)}
                                                    type="number"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="outlined">
                            CANCELAR
                        </Button>
                        <Button onClick={() => pay()} color="primary" variant="contained">
                            CONFIRMAR
                        </Button>
                    </DialogActions>
                </>
            )}
            {linkPixCopiaCola && (
                <>
                    <DialogTitle id="form-dialog-title">PAGAMENTO POR PIX</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <img src={linkQrCodePix} height="350px" alt='QR CODE PIX'/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <span style={{fontSize:'12pt', fontWeight:'bold', wordWrap:'break-word'}}>
                                    {linkPixCopiaCola}
                                </span>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="contained">
                            FECHAR
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
