import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default function Snack({open, setOpen, time, message}) {
    return(
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            onClose={(event, reason) => {
                if (reason === 'clickaway') {
                    return;
                }      
                setOpen(false);
            }}
            autoHideDuration={time}
            message={message}
        />
    );
}