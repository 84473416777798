import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaSignOutAlt, FaUserCog } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuFluter from '../Menu/MenuFluter';

export default function TopBar({ toggle }) {
  const history = useHistory();
  const [menuUser, setMenuUser] = useState(null);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      zIndex: 999,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

  function logout() {
    const loginMaster = sessionStorage.getItem('loginMaster') || null;
    if(loginMaster){
      const { infoLogin, jwt } = JSON.parse(loginMaster);
      sessionStorage.setItem('infoLogin', JSON.stringify(infoLogin));
      sessionStorage.setItem('jwtRenove', jwt);
      history.push('/revenda');
    }else{
      sessionStorage.removeItem('infoLogin');
      sessionStorage.removeItem('jwtRenove');
      history.push('/');
    }
  }

  function openConfigUser() {
    const location = {
      pathname: '/cadastrousuario'
    }
    history.push(location);
  }

  const arrMenuUser = [
    { icon: FaUserCog, txt: 'PERFIL', func: openConfigUser },
    { icon: FaSignOutAlt, txt: 'SAIR', func: logout }
  ];

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => toggle((value) => !value)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}></Typography>
          <IconButton aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={0} color="default">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={e => setMenuUser(e.currentTarget)}
          >
            <AccountCircle />
          </IconButton>
          <MenuFluter
            anchorEl={menuUser}
            setAnchorEl={setMenuUser}
            arrMenu={arrMenuUser}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
}
