import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SelectValueForLabel from '../Selects/SelectValueForLabel';
import { AlertInfo } from '../Alert/Alert';
import api from '../../services/api';
import { addMountfromDate, dateUsd } from '../../services/date';
import './style.css';

export default function ModalLicense({ open, setOpen, info }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [openLoad, setOpenLoad] = useState(false);

    const [valor_license, setValorLicense] = useState(0);
    const [data_inicio, setDataInicio] = useState(dateUsd());
    const [data_expire, setDataExpire] = useState(addMountfromDate(dateUsd(), 12));
    const [periodo_license, setPeriodoLicense] = useState(12);
    const [payment_license, setPaymentLicense] = useState(0);
    const [cod_payment_license, setCodPaymentLicense] = useState("");

    async function handleAddLicense() {
        setOpenLoad(true);
        const data = qs.stringify({
            id_empresa: info.id,
            valor_license,
            data_inicio,
            data_expire,
            periodo_license,
            payment_license,
            cod_payment_license
        });
        const response = await api.post(`/license/createLicense/${infoLogin.id}/${jwt}`, data);
        if(response && response.status === 200 && response.data){
            if(response.data.success){
                AlertInfo('success', 'DADOS GRAVADOS');
                setOpen(false);
            }
        }else{
            console.log(response);
        }
        setOpenLoad(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(info && info.valor_suporte) setValorLicense(info.valor_suporte);
        // eslint-disable-next-line
    }, [info]);

    useEffect(() => {
        if(Number(valor_license) > 0){
            setValorLicense(Number(info.valor_suporte) * periodo_license)
        }
        // eslint-disable-next-line
    }, [periodo_license])

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth >
            {openLoad && (
                <div className="loading">
                    <CircularProgress size={100} />
                    <br />
                    <Typography variant="h6" className="info">
                        PROCESSANDO PAGAMENTO...
                    </Typography>
                    <Typography variant="h6" className="info">
                        AGUARDE...
                    </Typography>
                </div>
            )}
            {!openLoad && (
                <>
                    <DialogTitle id="form-dialog-title">EXECUTAR PAGAMENTO</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <SelectValueForLabel 
                                    margin="dense"
                                    label="PERIDO LICENÇA"
                                    value={periodo_license}
                                    setValue={setPeriodoLicense}
                                    options={[
                                        { value: 1, label: 'MENSAL' },
                                        { value: 2, label: 'BIMESTRAL' },
                                        { value: 3, label: 'TRIMESTRAL' },
                                        { value: 6, label: 'SEMESTRAL' },
                                        { value: 12, label: 'ANUAL' },
                                    ]}
                                    variant="outlined" 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="VALOR SUPORTE"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={valor_license}
                                    onChange={e => setValorLicense(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="DATA INICIO LICENÇA"
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    value={data_inicio}
                                    onChange={e => setDataInicio(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="DATA FINAL LICENÇA"
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    value={data_expire}
                                    onChange={e => setDataExpire(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <SelectValueForLabel 
                                    margin="dense"
                                    label="PAGAMENTO"
                                    value={payment_license}
                                    setValue={setPaymentLicense}
                                    options={[
                                        { value: 0, label: 'DINHEIRO' },
                                        { value: 1, label: 'BOLETO' },
                                        { value: 2, label: 'CARTÃO DE CREDITO' },
                                        { value: 6, label: 'PIX DINAMICO' },
                                        { value: 14, label: 'PIX ESTATICO' },
                                    ]}
                                    variant="outlined" 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="CODIGO COBRANÇA"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={cod_payment_license}
                                    onChange={e => setCodPaymentLicense(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="outlined">
                            CANCELAR
                        </Button>
                        <Button onClick={() => handleAddLicense()} color="primary" variant="contained">
                            CONFIRMAR
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
